import Vue from "vue/dist/vue"
import {mapActions, mapGetters} from "vuex";
import {trans} from "../../../../common/Translation/Translation";

export default Vue.component('PlacementForm', {
    props: ['participant'],

    data: function() {
        return {
            selectedZoneId: null
        };
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        ...mapGetters({
            findZone: 'placements/findZoneById',
            findSeat: 'placements/findSeatById',
            findParticipant: 'participantCollection/getParticipantById',
            getParticipantCompleteName: 'participantCollection/getCompleteName',

            countFreeSeatsOfZone: 'placements/countFreeSeatsOfZone',
            isSeatOccupied: 'placements/isSeatOccupied'
        }),

        zone() {
            const zoneId = this.seat?.zoneId ?? this.selectedZoneId;

            return this.findZone(zoneId);
        },

        seat() {
            return this.findSeat(this.participant.seatId);
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        isDisplayed() {
            return (this.getParticipantCompleteName(this.participant) !== null);
        },

        getZoneChoiceLabel(id) {
            const zone = this.findZone(id);

            if (zone === null) {
                return 'UNEXISTING_ZONE';
            }

            const freeSeatsAmount = this.countFreeSeatsOfZone(zone);

            let label = trans('entity.placement.zone.' + zone.uid + '.label') + ' ';

            if (freeSeatsAmount > 0) {
                label += '(' + freeSeatsAmount + ' place(s) disponible(s))';
            } else {
                label += '(Aucune place disponible)';
            }

            return label;
        },

        isZoneChoiceDisplayed(id) {
            return (this.findZone(id) !== null);
        },

        isZoneChoiceDisabled(id) {
            const zone = this.findZone(id);

            if (zone === null) {
                return true;
            }

            const freeSeatsAmount = this.countFreeSeatsOfZone(zone);

            return (freeSeatsAmount === 0 && zone.id !== this.seat?.zoneId);
        },

        getSeatChoiceLabel(id) {
            const
                seat = this.findSeat(id),
                participantUidx = seat.participant?.uidx || this.findParticipant(seat.participant?.id)?.uidx,
                isNotSameParticipant = (this.participant.uidx !== participantUidx)
            ;

            let label = seat.name + ' ';

            if (this.isSeatOccupied(seat) && isNotSameParticipant) {
                const name = seat.participant.completeName || this.getParticipantCompleteName(seat.participant);

                label += '(' + name + ')';
            }

            return label;
        },

        isSeatFieldDisabled() {
            return (this.zone === null);
        },

        isSeatChoiceDisplayed(id) {
            const seat = this.findSeat(id);

            if (seat === null) {
                return false;
            }

            return (seat.zoneId === this.zone?.id);
        },

        isSeatChoiceDisabled(id) {
            const
                seat = this.findSeat(id),
                participantUidx = seat.participant?.uidx || this.findParticipant(seat.participant?.id)?.uidx,
                isNotSameParticipant = (this.participant.uidx !== participantUidx)
            ;

            return (this.isSeatOccupied(seat) && isNotSameParticipant);
        },

        ...mapActions({
            updateSeatState: 'placements/updateSeat',
            updateParticipantState: 'participantCollection/update'
        }),

        updateZone(event) {
            this.selectedZoneId = +event.target.value;

            const seatParticipantUidx = this.seat.participant?.uidx || this.findParticipant(this.seat.participant?.id)?.uidx;

            // Update old seat.
            // We also check UIDX to not remove occupant from seat if it has been filled from API after refresh.
            if (this.seat !== null && seatParticipantUidx === this.participant.uidx) {
                this.updateSeatState({
                    ...this.seat,
                    participant: null
                });
            }

            // Update participant.
            this.updateParticipantState({
                data: {
                    ...this.participant,
                    seatId: null
                }
            });
        },

        updateSeat(event) {
            let seatId = event.target.value;

            if (seatId !== null) {
                seatId = +seatId;

                // Update seat.
                const
                    previousSeat = (this.participant.seatId !== null)
                        ? this.findSeat(this.participant.seatId)
                        : null,
                    seat = this.findSeat(seatId)
                ;

                // Empty previous seat if the participant had one.
                if (previousSeat !== null) {
                    this.updateSeatState({
                        ...previousSeat,
                        participant: null
                    });
                }

                // Fill new seat from participant choice.
                this.updateSeatState({
                    ...seat,
                    participant: this.participant
                });

                // Update participant state.
                this.updateParticipantState({
                    data: {
                        ...this.participant,
                        seatId
                    }
                });
            }
        }
    }
})

