import Vue from "vue/dist/vue";
import {trans} from "../Translation/Translation";
import moment from "moment/moment";
import {media} from "../functions";

export default class {
    static addReplace(Vue, filterName = 'replace') {
        Vue.filter(filterName, (value, needle, replacement) => {
            return value.replace(needle, replacement);
        });

        return this;
    }

    /**
     * Add the translation filter in a Vue instance.
     *
     * @param {Vue} Vue
     * @param {string} filterName
     * @returns {this}
     */
    static addTranslation(Vue, filterName = 'trans') {
        Vue.filter(filterName, (value, params = {}) => {
            if (value === null || value === undefined) {
                return null;
            }

            return trans(value, params);
        });

        return this;
    }

    /**
     * Add the moment filter in a Vue instance.
     *
     * @param {Vue} Vue
     * @param {string} filterName
     * @returns {this}
     */
    static addMoment(Vue, filterName = 'moment') {
        Vue.filter(filterName, (value, format) => {
            if (value === null || value === undefined) {
                return null;
            }

            return moment().format(format);
        });

        return this;
    }

    static addMedia(Vue, filterName = 'media') {
        Vue.filter(filterName, (value, format) => {
            if (value === null || value === undefined) {
                return null;
            }

            return media(value);
        });

        return this;
    }
}
