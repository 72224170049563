import $ from "jquery";

/**
 * Get the client locale.
 *
 * @param {string} valueIfNull
 * @returns {string}
 */
export function getLocale(valueIfNull = 'fr') {
    return window._locale || valueIfNull;
}

/**
 * Tell whether a specific element is in the client viewport.
 *
 * @param {string} selector
 * @returns {boolean}
 */
export function elementIsInViewport(selector) {
    const jElement = $(selector);

    if (jElement.length > 0) {
        const elementTop = $(selector).offset().top,
            elementBottom = elementTop + $(this).outerHeight(),
            viewportTop = $(window).scrollTop(),
            viewportBottom = viewportTop + $(window).height();

        return (elementBottom > viewportTop && elementTop < viewportBottom);
    }

    return false;
}

/**
 * Scroll the viewport to the specified element.
 *
 * @param {string} idToScroll
 * @param {number} scrollSpeed
 * @returns {void}
 */
export function scroll(idToScroll = null, scrollSpeed = 700) {
    let scrollTop;

    if ($('body.mobile').length) {
        scrollTop = 70;
    } else {
        scrollTop = 150;
    }

    if (window.location.href.indexOf('#') > -1 || idToScroll !== null) {
        if (idToScroll === null) {
            idToScroll = window.location.href.split('#')[1];
        }

        $('html, body').animate({
            scrollTop: $('#' + idToScroll).offset().top - scrollTop
        }, scrollSpeed);
    }
}

/**
 * Highlight Card of recap clicked element
 *
 * @param {string} idToScroll
 * @param {number} scrollSpeed
 * @returns {void}
 */
export function highlightCard(participant) {

    $('.recap-block-participant-wrapper').each(function () {
        $(this).removeClass('highlight')
        if ($(this).attr("id") == "recap-participant-" + participant.uidx.replace('.', '')) {
            $(this).addClass('highlight')
        }
    })
}

/**
 *
 * @param str
 * @returns {null|*}
 */
export function toCamelCase(str = null) {
    if (str === null) {
        return null;
    }

    return str.replaceAll(/[\s_-]+([a-z])/g, (match, p1) => {
        return p1.toUpperCase();
    });
}

export function media(file) {
    return file.replace(/^\/?private/, '/medias');
}

export function object_length(obj) {
    return Object.keys(obj).length;
}

export function is_empty(value) {
    if (Array.isArray(value)) {
        return (value.length === 0);
    }

    return (value === undefined || value === null || value === '');
}

export function regex_quote(str) {
    return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}
