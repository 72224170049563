<template>
  <div class="placement-block-search">
    <div class="">
      <div class="registration-block-sub-title">
        <p>{{ 'form.placement.search.label'|trans }}</p>
        <div class="registration-block-sub-title-headline">
          <p>{{ 'form.placement.search.help'|trans }}</p>
        </div>

      </div>


      <div class="placement-block-search-input">
        <input type="text" @input="updateQuery($event)" :value="query">
        <button type="button" v-if="hasSearched()" @click="reset()">
          <svg fill="none" height="33" viewBox="0 0 24 24" width="33" xmlns="http://www.w3.org/2000/svg">
            <path d="m16 8-8 8m.00001-8 7.99999 8" stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="1.5"/>
          </svg>
        </button>
      </div>

      <div v-if="hasSearched()" class="placement-block-search-results">
        <p v-if="isLoading">{{ 'form.placement.search.results.title.in-progress'|trans }}</p>

        <div v-else>
          <div class="placement-block-search-results-title">
            <p>{{ 'form.placement.search.results.title.done'|trans }}</p>
          </div>

          <div class="placement-block-search-results-list">
            <table>
              <thead>
              <tr>
                <th>{{ 'form.placement.search.results.table.head.participant'|trans }}</th>
                <th>{{ 'form.placement.search.results.table.head.seat'|trans }}</th>
                <th>{{ 'form.placement.search.results.table.head.left-free-seats'|trans }}</th>
              </tr>
              </thead>

              <tbody>
              <tr v-if="hasResults()" v-for="item in results">
                <td>{{ item.firstName }} {{ item.lastName }}</td>

                <td v-if="item.seat === null" colspan="99">
                  {{ 'form.placement.search.results.table.body.empty-seat'|trans }}
                </td>

                <td v-if="item.seat">
                  {{ ('entity.placement.zone.' + item.seat.zone.uid + '.label')|trans }} /
                  {{
                    'form.placement.search.results.table.body.left-free-seats.label'|trans({'%seat%': item.seat.name})
                  }}
                </td>

                <td v-if="item.seat">
                                <span v-if="countFreeSeatsOfZone(findZone(item.seat.zone.id)) > 0">
                                    {{
                                    'form.placement.search.results.table.body.left-free-seats.availabilities.many'|trans({
                                      '%n%': countFreeSeatsOfZone(findZone(item.seat.zone.id))
                                    })
                                  }}
                                </span>

                  <span v-else>
                                    {{
                      'form.placement.search.results.table.body.left-free-seats.availabilities'|trans()
                    }}
                                </span>
                </td>
              </tr>

              <tr v-if="!hasResults()">
                <td colspan="99" class="empty">{{ 'form.placement.search.results.table.body.empty'|trans }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipantApi from "../../api/participant";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      query: null,
      results: [],

      isLoading: false,

      timeoutId: null,
      timeoutBeforeSearching: 1000
    }
  },

  /**
   * All computed properties of the component.
   *
   * @property {object}
   */
  computed: {
    ...mapGetters({
      findZone: 'placements/findZoneById',
      countFreeSeatsOfZone: 'placements/countFreeSeatsOfZone'
    })
  },

  /**
   * All methods of the component.
   *
   * @property {object}
   */
  methods: {
    hasSearched() {
      return !(this.query === null || this.query === '');
    },

    hasResults() {
      return (this.results.length > 0);
    },

    async search() {
      if (!this.hasSearched()) {
        return;
      }

      this.isLoading = true;

      const response = await ParticipantApi.search(this.query);

      this.isLoading = false;
      this.results = response.data;
    },

    reset() {
      this.query = null;
      this.results = [];
    },

    updateQuery(event) {
      this.query = event.target.value;

      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(this.search, this.timeoutBeforeSearching);
    }
  },

  /**
   * Executed when each time the component is created.
   *
   * @method
   * @returns {void}
   */
  created() {

  }
}
</script>
