import Vue from "vue/dist/vue";
import {mapActions, mapGetters, mapState} from "vuex";
import $ from "jquery";
import AbsentParticipantForm from "../AbsentParticipantForm";
import {updateState} from "../../../../common/Vue/VueHelper";

export default Vue.component('AgencyForm', {
    /**
     * Vue components inside this one.
     *
     * @property {object}
     */
    components: {
        AbsentParticipantForm
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            registration: state => state.registration,
            isParticipating: state => state.registration.isParticipating,
            participants: state => state.participantCollection.all
        }),

        ...mapGetters({
            findAgency: 'agency/find'
        })
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions({
            updateRegistrationState: 'registration/update'
        }),

        isAgencyChoiceDisabled(id) {
            if (id === null || id === undefined) {
                return false;
            }

            // On edit mode, impossible to switch agency.
            if (this.registration.id !== null) {
                return (id !== this.registration.agencyId);
            }

            const agency = this.findAgency(id);

            if (agency === null || agency === undefined) {
                return false;
            }

            if (agency.isParticipating === false) {
                return true;
            } else if (this.isParticipating === false && agency.isParticipating) {
                return true;
            }

            return false;
        },

        updateRegistration(event) {
            if (this.registration.id !== null) {
                return;
            }

            const
                registration = {...this.registration},
                agencyId = event.target.value
            ;

            if (agencyId) {
                const agency = this.findAgency(+agencyId);
                let isParticipating = this.isParticipating;

                // Unselect not participating if impossible
                if (isParticipating === false && agency.isParticipating) {
                    isParticipating = null
                } else if (isParticipating && agency.isParticipating === false) {
                    isParticipating = null;
                }

                this.updateRegistrationState({
                    data: {
                        ...registration,
                        isParticipating,
                        agencyId: +agencyId
                    }
                });

                return;
            }

            this.updateRegistrationState({
                data: {
                    ...registration,
                    agencyId
                }
            });
        }
    }
})

