<template>
    <div v-if="registration.isParticipating" class="recap-block">
        <div class="recap-block-header">
            <h3>{{ 'form.recap.title'|trans }}</h3>
        </div>

        <div class="recap-block-content">

            <div class="recap-block-container">
                <p class="recap-block-date">
                    <span v-if="registration.agencyId === null || registration.agencyId === undefined"
                          :inner-html.prop="'form.recap.date.default'|trans({'%date%': currentDate})">
                    </span>

                    <span v-else
                          :inner-html.prop="'form.recap.date.withAgency'|trans({'%date%': currentDate, '%agency%': findAgency(registration.agencyId).name})">
                    </span>
                </p>

                <div class="recap-block-content">

                    <div class="recap-block-agency" v-if="agency.name && displayCompleteInformation">
                        <div class="recap-block-agency-wrapper">
                            <div class="recap-block-agency-title">
                                <div class="bold">
                                    <span class="recap-bold">{{ 'form.recap.agency.title'|trans }}</span>
                                </div>
                            </div>

                            <div class="recap-block-agency-complete-infos">
                                <div v-if="agency.name" class="recap-block-agency-name"
                                     :inner-html.prop="'form.recap.agency.name'|trans({'%name%': agency.name})">
                                </div>

                                <div v-if="agency.type" class="recap-block-agency-type"
                                     :inner-html.prop="'form.recap.agency.type'|trans({'%type%': trans('entity.agency.type.' + agency.type + '.label')})">
                                </div>

                                <div v-if="agency.corporateName" class="recap-block-agency-corporate-name"
                                     :inner-html.prop="'form.recap.agency.corporateName'|trans({'%corporateName%': agency.corporateName})">
                                </div>
                            </div>
                        </div>
                    </div>

                    <form-recap-participant v-if="participants.length > 0"></form-recap-participant>
                    <form-recap-room v-if="rooms.length > 0"></form-recap-room>

                    <div class="recap-block-tarifaire" v-if="participants.length > 0" style="width: 100%!important;">
                        <h4>Récapitulatif tarifaire</h4>

                        <div class="recap-block-tarifaire-container">
                            <div class="recap-block-tarifaire-wrapper">

                                <div class="recap-block-tarifaire-title">

                                    <div class="bold"><span class="recap-bold">Détails</span></div>
                                </div>

                                <div class="recap-block-tarifaire-complete-infos">
                                    <div v-if="getPartyPackPrice({formatted: false}) > 0">
                                        <span class="recap-bold">
                                            Pack sans hébergement ({{ getPartyPackPrice({unit: true}) }}
                                            &times; {{ countParticipantsWithPartyPack() }}) :
                                        </span>

                                        {{ getPartyPackPrice() }}
                                    </div>

                                    <div v-if="rooms.length > 0">
                                        <!--                                        <span class="recap-bold">-->
                                        <!--                                            Pack avec hébergement ({{ rooms.length }}-->
                                        <!--                                            {{ rooms.length > 1 ? "chambres" : "chambre" }}) : Oui-->
                                        <!--                                        </span>-->

                                        <!--                                        <br>-->

                                        <div v-for="room in rooms">
                                            <span class="recap-bold">{{
                                                    'form.recap.hotel.' + findHotelById(room.hotelId).uid + '.label'|trans({
                                                        '%price%': getPriceNet(room.hotelId, room.roomTypeId),
                                                        '%roomType%' : getRoomType(room)
                                                    })|replace(/\sTTC/, '')
                                                }} :
                                            </span>
                                            <span>{{ getPriceRoom_oneRoom(room.hotelId, room.roomTypeId) * room.occupantsUidx.length }}€
                                            </span>
<!--                                            <span class="recap-bold">{{-->
<!--                                                    countRoom(rooms, room.hotelId, room.roomTypeId)-->
<!--                                                }} {{-->
<!--                                                    'form.recap.hotel.' + findHotelById(room.hotelId).uid + '.label'|trans({'%price%': getPriceNet(room.hotelId, room.roomTypeId)})|replace(/\sTTC/, '')-->
<!--                                                }} :-->
<!--                                            </span>-->
<!--                                            <span v-if="countSuppelement(rooms, room.hotelId, room.roomTypeId) > 0"> + {{-->
<!--                                                    countSuppelement(rooms, room.hotelId, room.roomTypeId)-->
<!--                                                }} supplément de {{-->
<!--                                                    findPriceById(room.hotelId, room.roomTypeId).supplement.priceInCents / 100-->
<!--                                                }} €</span>-->
                                        </div>
                                    </div>

                                    <div v-if="countInsurances > 0">
                                        <span class="recap-bold">
                                            Souscription à l'assurance
                                            ({{ getInsurancePrice(true, true) }} &times; {{ countInsurances }}) :
                                        </span>

                                        <span>{{ getInsurancePrice(false, true) }}</span>
                                    </div>

                                    <br>

                                    <!--<div v-if="rooms.length > 0">
                                      <span>Prix hébergement : <b>{{ getRoomingPrice() }} net</b></span>
                                    </div>-->

                                    <div class="recap-block-tarifaire-total-price">
                                        <div v-if="getTotalPrice({formatted: false}) > 0" class="total-price">
                                            <h5>Prix total</h5>
                                            <div><b>{{ getTotalPrice({rooms}) }} NET</b></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="recap-block-echeancier" v-if="isDeferredSelected()">

                        <h4>Echéancier</h4>

                        <div class="recap-block-echeancier-container">
                            <div class="recap-block-echeancier-wrapper">
                                <div class="recap-block-echeancier-title">

                                    <div class="bold"><span class="recap-bold">Détails</span></div>
                                </div>

                                <div class="recap-block-tarifaire-complete-infos">
                                    <table>
                                        <tr v-for="n in getAmountOfPayments()">
                                            <td class="recap-bold">
                                                Echéance n°{{ n }}
                                            </td>

                                            <td>{{ getDeferredMonth(n) }}</td>
                                            <td>{{ getFracPrices()[n - 1] }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import FormRecapParticipant from "./Participant/FormRecapParticipant";
import FormRecapRoom from "./Room/FormRecapRoom";
import {trans} from "../../../../common/Translation/Translation";
import {ske} from "../../../../common/Ske/Ske";
import moment from "moment/moment";
import {is_empty} from "../../../../common/functions";

export default {
    /**
     * Vue components inside this one.
     *
     * @property {object}
     */
    components: {
        FormRecapRoom,
        FormRecapParticipant
    },

    /**
     * Local data of the components.
     *
     * @property {array}
     */
    data: function () {
        return {
            displayCompleteInformation: true,
            currentDate: moment().locale('fr').format('DD MMMM YYYY - HH:mm')
        }
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            registration: state => state.registration,
            agency: state => state.agency,
            participants: state => state.participantCollection.all,
            rooms: state => state.roomCollection.all,
            transports: state => state.transport.all,
            hotelRoomType: state => state.availabilities.hotelRoomType,
            roomTypes: state => state.roomTypes.all,
            paymentMethod: state => state.billing_order.paymentMethod
        }),

        ...mapGetters({
            findPack: 'participationPack/findById',
            findPackByUid: "participationPack/findByUid",
            findAgency: 'agency/find',
            findHotelById: 'hotels/findById',
            findPriceById: "availabilities/findByHotelAndRoomTypeObject"
        }),

        countInsurances() {
            return this.participants.filter(p => p.hasInsurance).length;
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */

        trans,
        moment,

        isPromoPricingEnded() {
            return ske().parameters.isPromoPricingEnded;
        },

        isDeferredSelected() {
            return (this.paymentMethod === 'deferred-x3');
        },

        getPrice(property, formatted = true, multiplier = null) {
            const value = ske().parameters[property];

            if (value === null) {
                return null;
            }

            if (multiplier !== null) {
                const price = (value.priceInCents * 1.2 * multiplier);

                return (formatted)
                    ? Intl.NumberFormat('fr-FR', {style: 'currency', currency: value.currency}).format(price / 100)
                    : price;
            }

            return (formatted)
                ? Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: value.currency
                }).format(value.priceInCents * 1.2 / 100)
                : (value.priceInCents * 1.2);
        },

        getParticipationPackPrice(options = {}) {
            const
                unit = options.unit ?? false,
                formatted = options.formatted ?? true,
                property = (this.isPromoPricingEnded())
                    ? 'participationPackPrice'
                    : 'participationPackPromoPrice';
            ;

            return this.getPrice(property, formatted, (unit) ? null : this.participants.length);
        },

        countParticipantsWithPartyPack() {
            const pack = this.findPackByUid('no-rooming-pack');

            return this.participants.filter(p => p.participationPackIds.includes(pack.id)).length;
        },

        getPartyPackPrice(options = {}) {
            const
                unit = options.unit ?? false,
                formatted = options.formatted ?? true,

                pack = this.findPackByUid('no-rooming-pack'),
                numberOfParticipantsWithPack = this.countParticipantsWithPartyPack(),

                priceProperty = (this.isPromoPricingEnded())
                    ? 'price' : 'promoPrice'
            ;

            let totalPrice = (unit) ? pack[priceProperty].priceInCents : (pack[priceProperty].priceInCents * numberOfParticipantsWithPack);

            totalPrice = (totalPrice * (pack.vat / 100 + 1));

            return (formatted)
                ? Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(totalPrice / 100)
                : totalPrice;
        },

        getShuttlePrice(options = {}) {
            const
                unit = options.unit ?? false,
                formatted = options.formatted ?? true
            ;

            return this.getPrice('transportPrice', formatted, (unit) ? null : this.countShuttles());
        },

        countShuttles() {
            return this.transports.reduce((count, transport) => (count + +transport.wantShuttle + +transport.wantShuttleBack), 0);
        },

        getInsurancePriceWithWat(unit = false, formatted = false) {
            let price = ske().parameters.insurancePrice.priceInCents;

            if (!unit) {
                price = (price * this.countInsurances);
            }

            return (formatted)
                ? Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(price / 100)
                : price;
        },

        getInsurancePrice(unit = false, formatted = false) {
            let price = ske().parameters.insurancePrice.priceInCents;

            if (!unit) {
                price = (price * this.countInsurances);
            }

            return (formatted)
                ? Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(price / 100)
                : price;
        },

        countRoom(rooms, hotelId) {
            let count = 0;

            rooms.forEach((room) => {
                if (room.hotelId === hotelId) {
                    count += room.occupantsUidx.length;
                }
            });

            return count;
        },

        countSuppelement(rooms, hotelId, roomTypeId) {
            let count = 0;

            rooms.forEach((room) => {
                if (room.hotelId === hotelId && room.roomTypeId === roomTypeId) {
                    if (this.findPriceById(hotelId, roomTypeId).supplement.priceInCents > 0) {
                        count++;
                    }
                }
            });

            return count;
        },

        getPriceRoom_oneRoom(hotelId, roomTypeId) {
            let price = 0;
            let pivot = this.findPriceById(hotelId, roomTypeId)

            if (pivot.hotelId === hotelId && pivot.roomTypeId === roomTypeId) {
                price = (this.isPromoPricingEnded())
                    ? pivot.price.priceInCents / 100
                    : pivot.promoPrice.priceInCents / 100;
            }

            return price;
        },
        getPriceNet(hotelId, roomTypeId) {
            return this.findPriceById(hotelId, roomTypeId).price.priceInCents / 100

        },
        getRoomType(room){
            var roomTypeUid = this.roomTypes.find(a => (a.id === room.roomTypeId)).uid
            return roomTypeUid.charAt(0).toUpperCase() + roomTypeUid.slice(1);
        },

        delDoublons(rooms) {
            let newListRooms = [];
            let listHotelAlreadyRegister = [];

            rooms.forEach((room) => {
                if (is_empty(newListRooms)) {
                    newListRooms.push(room);
                    listHotelAlreadyRegister.push(room.hotelId);
                } else {
                    if (!listHotelAlreadyRegister.includes(room.hotelId)) {
                        newListRooms.push(room);
                        listHotelAlreadyRegister.push(room.hotelId);
                    }
                }
            })

            return newListRooms;
        },

        getRoomingPrice(options = {}) {
            const formatted = options.formatted ?? true;

            let price = 0;

            for (const room of this.rooms) {
                if (room.roomTypeId > 1) {
                    price += (this.isPromoPricingEnded())
                        ? this.hotelRoomType.find(a => (a.hotelId === room.hotelId && a.roomTypeId === room.roomTypeId)).price.priceInCents
                        : this.hotelRoomType.find(a => (a.hotelId === room.hotelId && a.roomTypeId === room.roomTypeId)).promoPrice.priceInCents;
                    // price += this.hotelRoomType.find(a => (a.hotelId === room.hotelId && a.roomTypeId === room.roomTypeId)).price.priceInCents + this.hotelRoomType.find(a => (a.hotelId === room.hotelId && a.roomTypeId === room.roomTypeId)).supplement.priceInCents;
                } else {
                    price += (this.isPromoPricingEnded())
                        ? this.hotelRoomType.find(a => (a.hotelId === room.hotelId && a.roomTypeId === room.roomTypeId)).price.priceInCents + this.hotelRoomType.find(a => (a.hotelId === room.hotelId && a.roomTypeId === room.roomTypeId)).supplement.priceInCents
                        : this.hotelRoomType.find(a => (a.hotelId === room.hotelId && a.roomTypeId === room.roomTypeId)).promoPrice.priceInCents + this.hotelRoomType.find(a => (a.hotelId === room.hotelId && a.roomTypeId === room.roomTypeId)).supplement.priceInCents;
                }
            }

            return (formatted)
                ? Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(price / 100)
                : price;
        },

        getAllPackPrice() {
            let price = 0;

            for (const participant of this.participants) {
                for (const packId of participant.participationPackIds) {
                    const pack = this.findPack(packId);

                    price += pack.price.priceInCents;
                    price = (price * (pack.vat / 100 + 1));
                }
            }

            return price;
        },

        getTotalRoomsPrice(rooms){
            var price = 0;
            for (var room of rooms){
                const quantity = room.occupantsUidx.length
                const roomPrice = this.findPriceById(room.hotelId, room.roomTypeId)
                price += roomPrice.price.priceInCents * quantity
            }
            return price;
        },

        getTotalPrice(options = {}) {
            var price = 0;
            const
                formatted = options.formatted ?? true,
                opt = {formatted: false}
            ;
            price += this.getInsurancePrice() + this.getTotalRoomsPrice(this.rooms);

            return (formatted)
                ? Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(price / 100)
                : price;
        },

        getAllHotelsPrice(options = {}) {
            let priceAll = 0;
            this.hotelRoomType.forEach((element) => {
                priceAll += element.price.priceInCents + element.supplement.priceInCents
            })

            const
                formatted = options.formatted ?? true,
                price = (priceAll)
            ;

            return (formatted)
                ? Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(price / 100)
                : price;
        },

        getAmountOfPayments() {
            return (this.isDeferredSelected()) ? 3 : 1;
        },

        getDeferredMonth(offset) {
            const
                months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', ' décembre'],
                date = new Date
            ;

            if (date.getDate() < 20) {
                offset--;
            }

            date.setDate(1);
            date.setMonth(date.getMonth() + offset);

            return months[date.getMonth()] + ' ' + date.getFullYear();
        },

        getFracPrices() {
            const
                totalPrice = this.getTotalPrice({formatted: false}),
                amountOfPayments = this.getAmountOfPayments()
            ;

            if (!this.isDeferredSelected()) {
                return totalPrice;
            }

            const
                frac = (totalPrice / amountOfPayments),
                roundedFrac = Math.floor(frac),
                missing = (totalPrice - (roundedFrac * amountOfPayments)),
                truncatedMissing = Math.trunc(missing),

                fracLists = []
            ;

            for (let i = 1; i < amountOfPayments; i++) {
                fracLists.push(roundedFrac);
            }

            fracLists.unshift(roundedFrac + truncatedMissing);

            for (let i in fracLists) {
                fracLists[i] = Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(fracLists[i] / 100)
            }

            return fracLists;
        }
    },

    created() {
        setInterval(() => (this.currentDate = moment().locale('fr').format('DD MMMM YYYY - HH:mm')), 60000);
    }
};
</script>
