import Vue from "vue";
import participationPackApi from "../../../../api/participationPack";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * @property {object}
     */
    state: () => ({
        _participationPackState: () => ({
            id: null,
            uid: null,
            unlockRoomingForm: null
        }),

        all: []
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        findById: state => id => {
            return state.all.find(pack => (pack.id === id));
        },

        findByUid: state => uid => {
            return state.all.find(pack => (pack.uid === uid));
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        async loadApi({commit}) {
            const response = await participationPackApi.participationPack.all();

            commit('reset');

            for (const item of response.data) {
                commit('add', {data: item});
            }

            return response;
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        /*
         * Here, you can import methods from the helper.
         *
         * ...helperMutations(['myMutation']),
         * or
         * ...helperMutations({
         *     otherMutationName: myMutation
         * }),
         */

        reset(state) {
            Vue.set(state, 'all', []);
        },

        /**
         * Add a new participation pack.
         *
         * @param {object} state
         * @param {object} data
         */
        add(state, {data = {}}) {
            const participationPack = {
                ...state._participationPackState(),
                ...data
            };

            state.all.push(participationPack);
        }
    },
}
