import axios from "axios";

export default {
    participationPack: {
        async all() {
            return axios.get('/api/participationPack', {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json'
                }
            }).then(response => response.data);
        }
    },
}
