import Vue from "vue/dist/vue";
import {mapActions, mapGetters, mapState} from "vuex";
import AgencyForm from "../AgencyForm";
import AbsentParticipantForm from "../AbsentParticipantForm";
import ParticipantCollection from "../ParticipantCollection";
import RoomCollection from "../Rooming/RoomCollection";
import PlacementCollection from "../Placement/PlacementCollection";
import FormRecap from "../FormRecap/FormRecap";
import CentralizerForm from "./CentralizerForm";
import BillingForm from "../BillingForm";
import BillingPaymentMethodForm from "../BillingForm/BillingPaymentMethodForm";
import SubmitRegistrationButton from "./SubmitRegistrationButton";
import {updateState} from "../../../../common/Vue/VueHelper";

export default Vue.component('RegistrationForm', {
    /**
     * Vue components inside this one.
     *
     * @property {object}
     */
    components: {
        AgencyForm,
        AbsentParticipantForm,
        ParticipantCollection,
        RoomCollection,
        PlacementCollection,
        FormRecap,
        CentralizerForm,
        BillingForm,
        BillingPaymentMethodForm,
        SubmitRegistrationButton
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            registration: state => state.registration,
            isParticipating: state => state.registration.isParticipating,
            wantShuttle: state => state.registration.wantShuttle,
            wantShuttleBack: state => state.registration.wantShuttleBack,
            roomingPack: state => state.registration.roomingPack,
            partyPack: state => state.registration.partyPack,
            participants: state => state.participantCollection.all,
            hasParticipatedToOldEvents: state => state.registration.hasParticipatedToOldEvents
        }),

        ...mapGetters({
            findAgency: "agency/find"
        }),

        isEditMode() {
            return (this.registration.id !== null);
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions({
            update: 'registration/update'
        }),

        /**
         * Get the value from the event, then pass it to the update action.
         *
         * @param {string} prop
         * @param {Event} event
         */
        updateState(prop, event) {
            updateState(this.registration, prop, event, this.update);
        },

        syncOldEvents(event) {
            const
                selectedEvent = event.target.value,
                currentEvents = (this.registration.hasParticipatedToOldEvents !== null)
                    ? [...this.registration.hasParticipatedToOldEvents] : []
            ;

            if (currentEvents.includes(selectedEvent)) {
                const index = currentEvents.indexOf(selectedEvent);

                currentEvents.splice(index, 1);
            } else {
                currentEvents.push(selectedEvent);
            }

            if (selectedEvent === 'none') {
                //delete all
                currentEvents.splice(0, currentEvents.length);
                currentEvents.push('none');
            } else {
                //delete none
                const index = currentEvents.indexOf('none');

                if (index !== -1) {
                    currentEvents.splice(index, 1);
                }
            }

            this.update({
                data: {
                    ...this.participant,
                    hasParticipatedToOldEvents: currentEvents
                }
            });
        },

        isNotParticipatingChoiceDisabled() {
            const agency = this.findAgency(this.registration.agencyId);

            if (agency === null || agency === undefined) {
                return false;
            }

            return (agency.numberOfRegistrations > 0);
        }
    },

    /**
     * Executed when each time the component is created.
     *
     * @method
     * @returns {void}
     */
    created() {
        this.$store.dispatch('registration/load');
    }
});

