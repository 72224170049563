import {trans} from "../../../../../common/Translation/Translation";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * Use the VuexState#create method to define your state:
     *     VuexState.create({ ... })
     *
     * The "create" static method needs one object argument, containing all property names as keys,
     * and property object as values.
     *
     * Property objects should have a "type" property. They could also have two facultative properties:
     * - "formatter", which must have the name of one of the methods of the FieldFormatter class.
     * - "default", which is the default value of the property.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        _roomTypeState: () => ({
            id: null,
            uid: null,
            prefix: null,
            nbPersons: null
        }),

        all: []
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        findById: (state) => (id) => {
            return state.all.find(rt => (rt.id === +id)) || null;
        },
        getLabel: () => (roomType, options = {}) => {
            const
                label = trans('entity.rooming.roomType.' + roomType.uid + '.label'),
                {withDetails = false} = options
            ;

            return (withDetails)
                ? (label + ' ' + trans('entity.rooming.roomType.' + roomType.uid + '.details'))
                : label;
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        /**
         * Load data from API.
         *
         * @param {object} rootState
         * @param {object} getters
         * @param {function} commit
         * @returns {Promise}
         */
        load({rootState, getters, commit}) {
            return new Promise(resolve => {
                for (const pivot of rootState.data.hotelRoomTypePivot) {
                    const roomType = getters.findById(pivot.roomType.id);

                    if (roomType === null) {
                        const data = {
                            ...pivot.roomType
                        }

                        commit('add', {data});
                    }
                }

                return resolve();
            });
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        add(state, {data = {}}) {
            const roomType = {
                ...state._roomTypeState(),
                ...data
            };

            state.all.push(roomType);
        }
    }
}
