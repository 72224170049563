var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "recap-block-rooms" },
      [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm._f("trans")("form.recap.rooming.title", {
                "%nb%": _vm.rooms.length,
              })
            )
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.rooms, function (room, index) {
          return _c(
            "div",
            {
              staticClass: "recap-block-room",
              class: { "first-child": index === 0 },
            },
            [
              _c("div", { staticClass: "recap-block-room-wrapper" }, [
                _c("div", { staticClass: "recap-block-room-number bold" }, [
                  _c("span", {
                    staticClass: "recap-bold clickable",
                    domProps: {
                      innerHTML: _vm._f("trans")(
                        "form.recap.rooming.label.default",
                        { "%n%": index + 1 }
                      ),
                    },
                    on: {
                      click: function ($event) {
                        _vm.scroll(_vm.getAnchor(room))
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "recap-block-room-complete-infos" }, [
                  _vm.hasMoreThanOneHotel
                    ? _c("div", {
                        domProps: {
                          innerHTML: _vm._f("trans")(
                            "form.recap.rooming.hotel",
                            {
                              "%hotel%": _vm.getHotelLabel(
                                _vm.findHotelById(room.hotelId)
                              ),
                            }
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._f("trans")(
                        "form.recap.rooming.room-type",
                        {
                          "%roomType%": _vm.getRoomTypeLabel(
                            _vm.findRoomTypeById(room.roomTypeId),
                            { withDetails: true }
                          ),
                        }
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._f("trans")(
                        "form.recap.rooming.occupants.label",
                        { "%occupants%": _vm.getOccupantsList(room) }
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.displayCompleteInformation ? _c("div") : _vm._e(),
                ]),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }