export default class {
    static RETURN_EXCEPTION_WHEN_NOT_FOUND = 0;
    static RETURN_NULL_WHEN_NOT_FOUND = 1;

    static basePath = null;

    /**
     *
     * @param object
     * @param path
     * @returns {*}
     */
    static resolveWithException(object = {}, path = null) {
        return this.resolve(object, path, this.RETURN_EXCEPTION_WHEN_NOT_FOUND);
    }

    /**
     *
     * @param object
     * @param path
     * @returns {*}
     */
    static resolveWithNull(object = {}, path = null) {
        return this.resolve(object, path, this.RETURN_NULL_WHEN_NOT_FOUND);
    }

    /**
     *
     * @param {object|array} object
     * @param {string|null} path
     * @param {number} returnType
     * @returns {any}
     */
    static resolve(object = {}, path = null, returnType = this.RETURN_EXCEPTION_WHEN_NOT_FOUND) {
        if (path === null) {
            return object;
        }

        if (this.basePath === null) {
            this.basePath = path;
        }

        path = path.split('.');

        const found = object[path[0]];

        if (typeof found === 'object' && found !== null) {
            path.shift();
            path = path.join('.');

            if (path === '') {
                path = null;
            }

            return this.resolve(found, path, returnType);
        }

        if (found === null || found === undefined) {
            if (returnType === this.RETURN_EXCEPTION_WHEN_NOT_FOUND) {
                throw new Error('Impossible to search the path "' + path + '" (complete path: "' + this.basePath +'") from something else than an array or an object.');
            } else if (returnType === this.RETURN_NULL_WHEN_NOT_FOUND) {
                return null;
            }
        }

        return found;
    }
}
