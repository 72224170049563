import Vue from "vue";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * Use the VuexState#create method to define your state:
     *     VuexState.create({ ... })
     *
     * The "create" static method needs one object argument, containing all property names as keys,
     * and property object as values.
     *
     * Property objects should have a "type" property. They could also have two facultative properties:
     * - "formatter", which must have the name of one of the methods of the FieldFormatter class.
     * - "default", which is the default value of the property.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        id: null,
        isParticipating: null,
        wantShuttle: null,
        wantShuttleBack: null,
        emailCopyTo: null,
        userId: null,
        agencyId: null,
        hasParticipatedToOldEvents: null
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {},

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        load({rootState, dispatch}) {
            const data = rootState.data.data;

            if (data) {
                dispatch('update', {
                    data: {
                        id: data.id,
                        isParticipating: data.isParticipating,
                        wantShuttle: data.wantShuttle,
                        wantShuttleBack: data.wantShuttleBack,
                        emailCopyTo: data.emailCopyTo,
                        userId: data.user?.id,
                        agencyId: data.agency?.id,
                        hasParticipatedToOldEvents: data.hasParticipatedToOldEvents,
                    }
                });
            }
        },
        update({state, commit}, {data}) {
            commit('update', {data});
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        update(state, {data}) {
            for (const prop in data) {
                Vue.set(state, prop, data[prop]);
            }
        }
    }
}
