<template>
    <div v-if="isDisplayed()">
        <div class="add-button">
            <p class="add-button-text">
                <slot></slot>
            </p>

            <button type="button" @click="openModal">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px"
                     viewBox="0 0 357 357" style="enable-background:new 0 0 357 357;" xml:space="preserve">
                    <g>
                        <g id="add">
                            <path fill="#fff" d="M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z"/>
                        </g>
                    </g>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapGetters({
            areAllParticipantsInRoom: 'participantCollection/areAllParticipantsInRoom'
        })
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        isDisplayed() {
            return !this.areAllParticipantsInRoom;
        },

        openModal() {
            this.$parent.createRoom();
        }
    }
};
</script>
