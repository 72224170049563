import {mapActions, mapGetters} from 'vuex';
import Vue from "vue/dist/vue";
import {updateState} from "../../../../../../common/Vue/VueHelper";

export default Vue.component('ParticipationPackForm', {
    /**
     * Local properties of the components.
     * They are obtained from the Vue template.
     *
     * If you define a "user" property, then in your template, you should have:
     * <my-component :user="..."> [...] </my-component>
     *
     * @property {array}
     */
    props: ['participant'],

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapGetters({
            findByParticipantUidx: 'participationPack/findByParticipantUidx',
            isGuest: 'participantCollection/isGuest',
            isParticipantInRoom: 'participantCollection/isInRoom',
            findPack: 'participationPack/findByUid'
        }),

        /*
         * parent() and guest() are defined because there are needed because of the prototype of the parent.
         */
        parent() {
            return this.$parent.parent || null;
        },

        guest() {
            return (this.isGuest(this.participant))
                ? this.participant
                : null;
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions({
            update: 'participationPack/update',
            updateParticipant: 'participantCollection/update'
        }),

        isPackSelected(packId) {
            return this.participant.participationPackIds.includes(packId);
        },

        /**
         * Get the value from the event, then pass it to the update action.
         *
         * @param {string} prop
         * @param {Event} event
         */
        updateState(prop, event) {
            updateState(this.participationPack, prop, event, this.update);
        },

        syncToParticipant(event) {
            const packId = +event.target.value;

            // Impossible to switch pack if the related participant is already inside a room.
            if (this.isParticipantInRoom(this.participant)) {
                alert("Ce participant est associé à une chambre. Veuillez le retirer de celle-ci si vous désirez changer de pack.");
                event.target.checked = !event.target.checked;

                return;
            }

            /*
             * This part works only if we can select MANY packs.
            const
                packIds = [...this.participant.participationPackIds],
                packId = +event.target.value,
                index = packIds.indexOf(packId)
            ;

            if (index >= 0) {
                packIds.splice(index, 1);
            } else {
                packIds.push(packId);
            }
             */

            this.updateParticipant({
                data: {
                    ...this.participant,
                    participationPackIds: [packId]
                }
            });
        }
    }
})

