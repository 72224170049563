import {trans} from "../../../../../common/Translation/Translation";
import Vue from "vue";
import {ske} from "../../../../../common/Ske/Ske";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * Use the VuexState#create method to define your state:
     *     VuexState.create({ ... })
     *
     * The "create" static method needs one object argument, containing all property names as keys,
     * and property object as values.
     *
     * Property objects should have a "type" property. They could also have two facultative properties:
     * - "formatter", which must have the name of one of the methods of the FieldFormatter class.
     * - "default", which is the default value of the property.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        _hotelState: () => ({
            id: null,
            uid: null,
            dateEnd: null,
            roomTypeIds: []
        }),

        all: []
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        hasMoreThanOne(state) {
            return (state.all.length > 1);
        },
        findById: (state) => (id) => {
            return state.all.find(h => (h.id === +id));
        },
        getLabel: () => (hotel) => {
            return trans('entity.rooming.hotel.' + hotel.uid + '.label-no-price')

            // const isPromoActive = !ske().parameters.isPromoPricingEnded;
            //
            // return (isPromoActive)
            //     ? trans('entity.rooming.hotel.' + hotel.uid + '.label-promo')
            //     : trans('entity.rooming.hotel.' + hotel.uid + '.label');
        },
        getDateEnd: () => (hotel) => {
            return hotel.dateEnd;
        },
        hasRoomTypeId: () => (hotel, roomTypeId) => {
            for (const rtId of hotel.roomTypeIds) {
                if (roomTypeId === rtId) {
                    return true;
                }
            }

            return false;
        },
        getRoomTypes: (state, getters, rootState, rootGetters) => (hotel) => {
            const roomTypes = [];

            for (const roomTypeId of hotel.roomTypeIds) {
                roomTypes.push(rootGetters["roomTypes/findById"](roomTypeId));
            }

            return roomTypes;
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        /**
         * Load data from API.
         *
         * @param {object} rootState
         * @param {object} getters
         * @param {function} commit
         * @returns {Promise}
         */
        load({rootState, getters, commit}) {
            return new Promise(resolve => {
                for (const pivot of rootState.data.hotelRoomTypePivot) {
                    const hotel = getters.findById(pivot.hotel.id);

                    if (hotel) {
                        const data = {
                            ...hotel,
                            roomTypeIds: hotel.roomTypeIds.filter(() => true)
                        };

                        data.roomTypeIds.push(pivot.roomType.id);

                        commit('update', {data});
                    } else {
                        const data = {
                            ...pivot.hotel,
                            roomTypeIds: [pivot.roomType.id]
                        }

                        commit('add', {data});
                    }
                }

                return resolve();
            });
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        add(state, {data = {}}) {
            const hotel = {
                ...state._hotelState(),
                ...data
            };

            state.all.push(hotel);
        },
        update(state, {data}) {
            const index = state.all.findIndex(h => (h.id === data.id));

            for (const prop in data) {
                Vue.set(state.all[index], prop, data[prop]);
            }
        },
    }
}
