export default class FieldFormatter {
    static string(value = null) {
        if (value === null) {
            return null;
        }

        return value.toString();
    }

    /**
     * Convert to boolean.
     *
     * @param {any} value
     * @returns {null|boolean}
     */
    static boolean(value = null) {
        if (value === null) {
            return null;
        }

        switch (typeof value) {
            case 'boolean':
                return value;

            case 'string':
                return (value === '1');

            case 'number':
                return (value === 1);
        }
    }

    /**
     * Capitalize the first word.
     *
     * @param {?string} value
     * @returns {?string}
     */
    static capitalizeFirst(value = null) {
        if (value === null) {
            return null;
        }

        return value
            // Capitalize first letter of string.
            .replace(/^[a-z]/, (corr) => corr.toUpperCase());
    }

    /**
     * Capitalize all words.
     *
     * @param {?string} value
     * @returns {?string}
     */
    static capitalizeAll(value = null) {
        if (value === null) {
            return null;
        }

        return FieldFormatter.capitalizeFirst(value)
            // Capitalize first letter of each word (string seperated by an apostrophe, a hyphen or a space).
            .replace(/([' -])([a-z])/g, (corr, str1, str2) => str1 + str2.toUpperCase());
    }

    /**
     * Format to uppercase.
     *
     * @param {?string} value
     * @returns {?string}
     */
    static uppercase(value = null) {
        if (value === null) {
            return null;
        }

        return value.toUpperCase();
    }

    /**
     * Format to lowercase.
     *
     * @param {?string} value
     * @returns {?string}
     */
    static lowercase(value = null) {
        if (value === null) {
            return null;
        }

        return value.toLowerCase();
    }

    /**
     * Format to a phone number.
     *
     * @param {?string} value
     * @returns {?string}
     */
    static phone(value = null) {
        if (value === null) {
            return null;
        }

        return value.replaceAll(/[^\d+]+/g, '').replaceAll(/(\d{2})/g, `$1 `);
    }

    /**
     * Format to an email.
     *
     * @param {?string} value
     * @returns {?string}
     */
    static email(value = null) {
        if (value === null) {
            return null;
        }

        return FieldFormatter.lowercase(value.replaceAll(/[^a-zA-Z\d@._-]/g, ''));
    }

    /**
     * Format to a zipcode.
     *
     * @param {?string} value
     * @returns {?string}
     */
    static zipCode(value = null) {
        if (value === null) {
            return null;
        }

        return value.replaceAll(/[^\d]/g, '')?.replace(/(\d{0,5})/, '$1');
    }

    /**
     * Tell whether the class has the given method.
     *
     * @param {string} method
     * @returns {boolean}
     */
    static hasMethod(method) {
        return (method in FieldFormatter);
    }
}
