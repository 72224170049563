import Vue from "vue";
import PlacementApi from "../../../api/placement";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * Use the VuexState#create method to define your state:
     *     VuexState.create({ ... })
     *
     * The "create" static method needs one object argument, containing all property names as keys,
     * and property object as values.
     *
     * Property objects should have a "type" property. They could also have two facultative properties:
     * - "formatter", which must have the name of one of the methods of the FieldFormatter class.
     * - "default", which is the default value of the property.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        _zoneState: () => ({
            id: null,
            uid: null
        }),

        _seatState: () => ({
            id: null,
            uid: null,
            name: null,
            zoneId: null
        }),

        zones: [],
        seats: []
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        findZoneById: state => id => {
            return state.zones.find(e => (e.id === +id)) || null;
        },

        countFreeSeatsOfZone: (state, getters) => zone => {
            return getters.getSeatByZone(zone).filter(e => !getters.isSeatOccupied(e)).length;
        },

        findSeatById: state => id => {
            return state.seats.find(e => (e.id === +id)) || null;
        },

        getSeatByZone: state => zone => {
            return state.seats.filter(e => (e.zoneId === zone.id));
        },

        isSeatOccupied: () => seat => {
            return (seat.participant !== null);
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        /**
         * Load data from API.
         *
         * @param {object} state
         * @param {object} getters
         * @param {function} commit
         * @returns {Promise}
         */
        async load({state, getters, dispatch}) {
            const
                response = await PlacementApi.get(),
                fetchedSeats = []
            ;

            for (const seat of response.data.seats) {
                seat.zoneId = seat.zone?.id;

                delete seat.zone;

                fetchedSeats.push(seat);
            }

            dispatch('clearZones');
            dispatch('clearSeats');

            dispatch('updateZone', response.data.zones);
            dispatch('updateSeat', fetchedSeats);
        },

        async reload({rootState, getters, dispatch}) {
            await dispatch('load');

            // Reset occupants as they were before reload.
            const participants = rootState.participantCollection.all;

            for (const participant of participants) {
                if (participant.seatId !== null) {
                    const seat = getters.findSeatById(participant.seatId);

                    dispatch('updateSeat', {
                        ...seat,
                        participant
                    });
                }
            }
        },

        updateZone({dispatch}, zones) {
            dispatch('update', {type: 'zones', data: zones});
        },

        removeZone({dispatch}, zones) {
            dispatch('remove', {type: 'zones', data: zones});
        },

        clearZones({dispatch}) {
            dispatch('clear', {type: 'zones'});
        },

        clearSeats({dispatch}) {
            dispatch('clear', {type: 'seats'});
        },

        updateSeat({dispatch}, seats) {
            dispatch('update', {type: 'seats', data: seats});
        },

        removeSeat({dispatch}, seats) {
            dispatch('remove', {type: 'seats', data: seats});
        },

        update({commit}, {type, data}) {
            if (!Array.isArray(data)) {
                data = [data];
            }

            commit('update', {type, data});
        },

        remove({commit}, {type, data}) {
            if (!Array.isArray(data)) {
                data = [data];
            }

            commit('remove', {type, data});
        },

        clear({state, commit}, {type}) {
            commit('clear', {type});
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        update(state, {type, data}) {
            for (const item of data) {
                const index = state[type].findIndex(e => (e.id === item.id));

                // Update if found.
                if (index >= 0) {
                    Vue.set(state[type], index, item);

                    return;
                }

                let baseState = null;

                switch (type) {
                    case 'zone':
                        baseState = state._zoneState();
                        break;

                    case 'seat':
                        baseState = state._seatState();
                        break;
                }

                // Add if not found.
                const newItem = {
                    ...baseState,
                    ...item
                };

                state[type].push(newItem);
            }
        },

        remove(state, {type, data}) {
            for (const item of data) {
                const index = state[type].findIndex(e => (e.id === item.id));

                // Remove if found.
                if (index >= 0) {
                    state[type].splice(index, 1);
                }
            }
        },

        clear(state, {type}) {
            Vue.set(state, type, []);
        }
    }
}
