<template>
    <div>
        <div class="overlay-modal" v-show="isOpen" @click="close"></div>

        <div class="room-modal" v-show="isOpen">
            <div class="room-modal-header">
                <div class="room-modal-fixed-title">
                    <h4 class="u-txt-uppercase">{{ 'form.rooming.modal.title' | trans }}</h4>
                </div>

                <button type="button" class="room-modal-close" @click="close">
                    <svg fill="none" height="33" viewBox="0 0 24 24" width="33" xmlns="http://www.w3.org/2000/svg">
                        <path d="m16 8-8 8m.00001-8 7.99999 8" stroke="#000" stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5"/>
                    </svg>
                </button>
            </div>

            <div class="room-modal-content">
                <p class="help" :inner-html.prop="'form.rooming.modal.help-multiple'|trans"
                   v-if="hasMoreThanOneHotel"></p>
                <p class="help" :inner-html.prop="'form.rooming.modal.help'|trans" v-else></p>

                <p class="dispo-pdf">
                    <a onclick="window.open('/registration/rooming/pdf');">Voir
                        les
                        disponibilitées</a></p>


                <div class="select-label">
                    <label>{{ 'form.rooming.occupants' | trans }} :</label>
                </div>

                <v-select
                    multiple
                    :placeholder="'form.rooming.modal.occupants.select.placeholder'|trans"
                    :options="getSelectableParticipants()"
                    :selectable="isSelectable"
                    :value="occupants"
                    label="_vSelectLabel"
                    @option:selecting="selectParticipant"
                    @option:deselecting="deselectParticipant">

                    <template v-slot:option="option">{{ getCompleteName(option) }}</template>

                    <template #no-options>{{ 'form.rooming.modal.occupants.select.noMoreOccupant'|trans }}</template>
                </v-select>

                <div class="room-modal-hotels" v-if="hasMoreThanOneHotel">
                    <label>{{ 'form.rooming.modal.hotel.label'|trans }}</label>

                    <select name="hotel" @change="selectHotel">
                        <option :selected="hotel === null" disabled>{{
                                'form.rooming.modal.hotel.placeholder'|trans
                            }}
                        </option>

                        <option v-for="hotelChoice in hotels"
                                :value="hotelChoice.id"
                                :selected="hotel && hotelChoice.id === hotel.id"
                                :disabled="hotelIsDisabled(hotelChoice)">
                            {{ getHotelLabel(hotelChoice) }}
                        </option>
                    </select>
                </div>

                <div class="room-form">
                    <div class="form-item typeOfRoom" v-if="roomTypes">
                        <label>{{ 'form.rooming.modal.room-type.label'|trans }}</label>

                        <div v-for="roomTypeChoice in roomTypes">
                            <div class="form-item-choice">
                                <input type="radio" name="room-type"
                                       :value="roomTypeChoice.id"
                                       :id="'room-type' + roomTypeChoice.id"
                                       :checked="roomType && roomTypeChoice.id === roomType.id"
                                       :disabled="isRoomTypeDisabled(roomTypeChoice)"
                                       @change="selectRoomType">

                                <label :for="'room-type' + roomTypeChoice.id">
                                    {{ getRoomTypeLabel(roomTypeChoice, {withDetails: true, withPrice : true, hotelId : hotel.id }) }}

                                    {{'form.rooming.modal.room-type.availabilities.price'|trans({'%price%': priceByRoomType(hotel.id, roomTypeChoice.id)})}}

                                    <span v-if="availabilities(hotel.id, roomTypeChoice.id) !== null">

                                    <span v-if="availabilities(hotel.id, roomTypeChoice.id) > 0">
                                        {{
                                            'form.rooming.modal.room-type.availabilities.any'|trans({'%n%': availabilities(hotel.id, roomTypeChoice.id)})
                                        }}
<!--                                      <div v-if="isRoomTypeDisabled(roomTypeChoice)">-->
<!--                                        <p style="color: #c2c0ba;" v-if="roomTypeChoice.id === 1"><b>Supplément hébergement en chambre simple : {{-->
<!--                                                supplement(hotel.id, roomTypeChoice.id)-->
<!--                                            }} € TTC</b></p>-->
<!--                                      </div>-->
<!--                                      <div v-else>-->
<!--                                          <p v-if="roomTypeChoice.id === 1"><b>Supplément hébergement en chambre simple : {{-->
<!--                                                  supplement(hotel.id, roomTypeChoice.id)-->
<!--                                              }} € TTC</b></p>-->
<!--                                      </div>-->
                                    </span>

                                    <span v-else>
                                        {{ 'form.rooming.modal.room-type.availabilities.none'|trans }}
                                    </span>
                                </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="room-submit-container">
                    <button type="button" class="submit c-button c-button-secondary" @click="submit"
                            :disabled="isSubmitDisabled()">
                        {{ 'form.rooming.modal.submit'|trans }}
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select/dist/vue-select'
import {mapState, mapGetters, mapActions} from 'vuex'
import $ from "jquery"
import {ske} from "../../../../../common/Ske/Ske";
import moment from "moment";

export default {
    /**
     * Vue components inside this one.
     *
     * @property {object}
     */
    components: {
        vSelect
    },

    /**
     * Local data of the components.
     *
     * @property {array}
     */
    data: function () {
        return {
            isOpen: false,
            occupants: [],
            roomType: null,
            hotel: null,
            idx: null,
            baseRoom: null
        }
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            roomTypes: state => state.roomTypes.all,
            hotels: state => state.hotels.all,
            participants: state => state.participantCollection.all
        }),
        ...mapGetters({
            // From module participant.
            getParticipantByUidx: 'participantCollection/getParticipantByUidx',
            participantsWithoutRoom: 'participantCollection/getParticipantsWithoutRoom',
            getCompleteName: 'participantCollection/getCompleteName',

            // From module rooming.
            findRoomByIdx: 'roomCollection/findRoomByIdx',
            getRoomOccupants: 'roomCollection/getOccupants',

            // From module roomTypes.
            findRoomTypeById: 'roomTypes/findById',
            getRoomTypeLabel: 'roomTypes/getLabel',

            // From module hotels.
            findHotelById: 'hotels/findById',
            hasMoreThanOneHotel: 'hotels/hasMoreThanOne',
            getRoomTypes: 'hotels/getRoomTypes',
            hotelHasRoomTypeId: 'hotels/hasRoomTypeId',
            getHotelLabel: 'hotels/getLabel',

            // From module availabilities.
            getAvailabilities: 'availabilities/findByHotelAndRoomType',
            findByHotelAndRoomTypeObject: 'availabilities/findByHotelAndRoomTypeObject',
            getTotalAvailabilitiesByHotel: 'availabilities/getTotalByHotel'
        }),

        roomTypes() {
            if (this.hotel === null) {
                return null;
            }

            return this.getRoomTypes(this.hotel);
        },

        /**
         * Tell whether we're editing an existing room or not.
         *
         * @returns {boolean}
         */
        isEditing() {
            return (this.idx !== null);
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions('roomCollection', ['add', 'update']),

        availabilities(hotelId, roomTypeId) {
            let availabilities = this.getAvailabilities(hotelId, roomTypeId);

            // If availabilities is null, so there is an infinite.
            if (availabilities === null) {
                return null;
            }

            // If it's the selected room type, we decrease the availabilities.
            if (this.roomType?.id === roomTypeId) {
                availabilities--;
            }

            if (this.isEditing) {
                // If the selected room type and hotel are the same as before editing, we increase the availabilities.
                if (hotelId === this.baseRoom.hotelId && roomTypeId === this.baseRoom.roomTypeId) {
                    availabilities++;

                    // If the room was already saved into the DB, then we increase once more.
                    if (this.baseRoom.id !== null) {
                        availabilities++;
                    }
                }
            }

            return availabilities;
        },

        priceByRoomType(hotelId, roomTypeId) {
            let price = this.findByHotelAndRoomTypeObject(hotelId, roomTypeId).price;

            if (price === null) {
                return null;
            }

            return price.priceInCents / 100;
        },

        supplement(hotelId, roomTypeId) {
            let supplement = this.findByHotelAndRoomTypeObject(hotelId, roomTypeId);

            // If availabilities is null, so there is an infinite.
            if (supplement === null) {
                return null;
            }

            supplement = supplement.supplement.priceInCents / 100;

            return supplement;
        },

        /**
         * Get the participants that are available inside the dropdown.
         * Participants which have already a room are not included.
         *
         * @returns {array}
         */
        getSelectableParticipants() {
            const participants = this.participantsWithoutRoom;

            /*if (this.isEditing) {
                const room = this.findRoomByIdx(this.idx);

                for (const occupant of this.getRoomOccupants(room)) {
                    participants.push(occupant);
                }
            }*/

            let selectableParticipants = [];

            for (const participant of participants) {
                const isAlreadyOccupant = (this.occupants.findIndex(o => (o.uidx === participant.uidx)) >= 0);

                if (!isAlreadyOccupant) {
                    selectableParticipants.push(participant);
                }
            }

            return selectableParticipants;
        },

        /**
         * Tell whether we can continue to select more participants.
         *
         * @returns {boolean}
         */
        isSelectable() {
            return true;
        },

        /**
         * Fired when a participant is selected as occupant.
         *
         * @param {object} occupant
         * @returns {void}
         */
        selectParticipant(occupant) {
            this.occupants.push(occupant);

            if (this.isRoomTypeDisabled(this.roomType)) {
                this.roomType = null;
            }
        },

        /**
         * Fired when a participant is removed from a room.
         *
         * @param {object} occupant
         * @returns {void}
         */
        deselectParticipant(occupant) {
            const index = this.occupants.findIndex(o => (o.idx === occupant.idx));

            this.occupants.splice(index, 1);

            if (this.isRoomTypeDisabled(this.roomType)) {
                this.roomType = null;
            }
        },

        /**
         *
         * @param {object} hotel
         * @returns {boolean}
         */
        hotelIsDisabled(hotel) {
            if (this.baseRoom?.hotelId === hotel.id) {
                return false;
            }

            if (hotel.dateEnd <= moment().format("YYYY-MM-DD")) {
                return true;
            }

            const total = this.getTotalAvailabilitiesByHotel(hotel);

            return (total === null) ? false : (total === 0);
        },

        /**
         * Fired when a hotel is selected.
         *
         * @param {Event} event
         * @returns {void}
         */
        selectHotel(event) {
            this.hotel = this.findHotelById($(event.target).val());

            if (this.roomType) {
                if (!this.hotelHasRoomTypeId(this.hotel, this.roomType.id) || this.availabilities(this.hotel.id, this.roomType.id) <= 0) {
                    this.roomType = null;
                }
            }
        },

        /**
         * Tell whether a room type is disabled or not.
         *
         * @param {?object} roomType
         * @returns {boolean}
         */
        isRoomTypeDisabled(roomType = null) {
            // Will be disabled if no room type given.
            if (roomType === null) {
                return true;
            }

            // Will not be disabled if it's the current selected room type.
            if (this.roomType?.id === roomType.id) {
                return false;
            }

            const
                nbPersonsNotCorrect = (ske().registrationModule.roomingModule.isRoomTypeSelectionStrict)
                    ? (roomType.nbPersons !== this.occupants.length) : (roomType.nbPersons < this.occupants.length),
                availabilities = this.availabilities(this.hotel.id, roomType.id),
                hasInfiniteAvailabilities = (availabilities === null),
                hasEnoughAvailabilities = (availabilities > 0)
            ;

            return (nbPersonsNotCorrect || (!hasEnoughAvailabilities && !hasInfiniteAvailabilities));
        },

        /**
         * Fired when a room type is selected.
         *
         * @param {Event} event
         * @returns {void}
         */
        selectRoomType(event) {
            this.roomType = this.findRoomTypeById($(event.target).val());
        },

        /**
         * Tell whether the submit button is disabled or not.
         *
         * @returns {boolean}
         */
        isSubmitDisabled() {
            const nbPersonsNotCorrect = (ske().registrationModule.roomingModule.isRoomTypeSelectionStrict)
                ? (this.roomType?.nbPersons !== this.occupants.length)
                : (this.roomType?.nbPersons < this.occupants.length);

            return (this.hotel === null || this.roomType === null || this.occupants.length === 0 || nbPersonsNotCorrect);
        },

        /**
         * Fired when the submit button is clicked.
         *
         * @returns {void}
         */
        submit() {
            const room = {
                roomTypeId: this.roomType.id,
                hotelId: this.hotel.id,
                occupantsUidx: this.occupants.map(o => o.uidx)
            };

            if (!this.isEditing) {
                this.add({data: room});
            } else {
                this.update({
                    data: {
                        ...room,
                        idx: this.idx
                    }
                });
            }

            this.close();
        },

        /**
         * Reset all data of the component.
         * The data are prefilled if an existing room is given.
         *
         * @param {object} room
         * @returns {void}
         */
        reset(room = null) {
            if (room === null) {
                this.roomType = null;
                this.hotel = (this.hasMoreThanOneHotel) ? null : this.hotels[0];
                this.occupants = [];
                this.idx = null;
                this.baseRoom = null;
            } else {
                this.roomType = this.findRoomTypeById(room.roomTypeId);
                this.hotel = this.findHotelById(room.hotelId);
                this.occupants = room.occupantsUidx.map(uidx => this.getParticipantByUidx(uidx));
                this.idx = room.idx;
                this.baseRoom = room;
            }
        },

        /**
         * Open the modal.
         *
         * @param {object} room
         * @returns {void}
         */
        open(room = null) {
            this.reset(room);
            this.isOpen = true;
        },

        /**
         * Close the modal.
         *
         * @returns {void}
         */
        close() {
            this.isOpen = false;
        }
    }
}
</script>
