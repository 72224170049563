import axios from "axios";

export default {
    async agencies(userId) {
        return axios({
                method: "get",
                url: '/api/agency/' + userId,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json'
                },
            }
        )
    },

    /**
     * Send the registration form data to the server.
     *
     * @param {FormData} data
     * @returns {AxiosPromise<any>}
     */
    async post(data) {
        return axios({
                method: "post",
                url: window.location.href,
                data,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    "Content-Type": "multipart/form-data"
                },
            }
        )
    }
}
