import axios from "axios";

export default {
    hotelRoomTypePivot: {
        async all() {
            return axios.get('/api/rooming/hotel-room-type', {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json'
                }
            }).then(response => response.data);
        }
    },
    hotels: {
        async all() {
            return axios.get('/api/hotel', {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json'
                }
            }).then(response => response.data);
        }
    },
    roomTypes: {
        async all() {
            return axios.get('/api/room-type', {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json'
                }
            }).then(response => response.data);
        }
    }
}
