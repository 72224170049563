var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.identityDocument.type
    ? _c("div", { staticClass: "recap-block-participant-identity-doc" }, [
        _c("h5", [
          _vm._v(
            _vm._s(
              _vm._f("trans")(
                "form.recap.participant-accompagnant-commons.identity-document.title"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "recap-block-participant-identity-doc-type" },
          [_vm._v(_vm._s(_vm.getTypeLabel(_vm.identityDocument)))]
        ),
        _vm._v(" "),
        _vm.areAllInputsDisplayed
          ? _c("div", [
              _vm.identityDocument.number
                ? _c("div", [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("trans")(
                            "form.recap.participant-accompagnant-commons.identity-document.number",
                            { "%number%": _vm.identityDocument.number }
                          )
                        ) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.identityDocument.lastNames
                ? _c("div", [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("trans")(
                            "form.recap.participant-accompagnant-commons.identity-document.lastNames",
                            { "%names%": _vm.identityDocument.lastNames }
                          )
                        ) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.identityDocument.firstNames
                ? _c("div", [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("trans")(
                            "form.recap.participant-accompagnant-commons.identity-document.firstNames",
                            { "%names%": _vm.identityDocument.firstNames }
                          )
                        ) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.identityDocument.nationality
                ? _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm._f("trans")(
                          "form.recap.participant-accompagnant-commons.identity-document.nationality",
                          { "%nationality%": _vm.identityDocument.nationality }
                        )
                      ) + "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.identityDocument.dateOfIssue
                ? _c("div", [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("trans")(
                            "form.recap.participant-accompagnant-commons.identity-document.dateOfIssue",
                            { "%date%": _vm.identityDocument.dateOfIssue }
                          )
                        ) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.identityDocument.dateOfExpiry
                ? _c("div", [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("trans")(
                            "form.recap.participant-accompagnant-commons.identity-document.dateOfExpiry",
                            { "%date%": _vm.identityDocument.dateOfExpiry }
                          )
                        ) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.identityDocument.dateOfBirth
                ? _c("div", [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("trans")(
                            "form.recap.participant-accompagnant-commons.identity-document.dateOfBirth",
                            { "%date%": _vm.identityDocument.dateOfBirth }
                          )
                        ) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }