import axios from "axios";

export class Translation {
    /**
     * Whether the data have been loaded or not.
     *
     * @type {boolean}
     * @private
     */
    static _loaded = false;

    /**
     * All translations. given by the server
     *
     * @type {object}
     */
    static translations = {};

    /**
     * Tell whether the data have been loaded or not.
     *
     * @returns {boolean}
     */
    static isLoaded() {
        return this._loaded;
    }

    /**
     * Throw an error is the config has not been loaded.
     *
     * @returns {void}
     */
    static checkIfHasBeenLoaded() {
        if (!this.isLoaded()) {
            throw new Error('Translations have not been loaded. Please do it by using load() or loadFromApi().');
        }
    }

    /**
     * Initialize the data from API call.
     *
     * @returns {Promise<void>}
     */
    static async loadFromApi(domains) {
        if (!this.isLoaded()) {
            const app = (await axios.get('/api/translation/', {
                params: {
                    q: (typeof domains === 'string') ? [domains] : domains
                }
            })).data.data;

            this.set(app || {});

            this._loaded = true;

            if (Object.keys(this.translations).length === 0) {
                console.warn('You have no translation.');
            }
        }
    }

    /**
     * Get the translation data.
     *
     * @returns {Object}
     */
    static get() {
        this.checkIfHasBeenLoaded();

        return this.translations;
    }

    /**
     * Set the translation data.
     *
     * @param0 {object} translations
     * @returns {this}
     */
    static set(translations) {
        this.translations = translations;

        return this;
    }

    /**
     * Find a translation by its key.
     *
     * @param {string} key
     * @param {object} params
     * @param {?object} translations
     * @returns {string}
     */
    static find(key, params = {}, translations = null) {
        this.checkIfHasBeenLoaded();

        if (!translations) {
            translations = this.get();
        }

        key = key.split('.');

        let found = translations[key[0]];

        if (typeof found === 'object'
            && !Array.isArray(found)
            && found !== null) {
            key.shift();

            return this.find(key.join('.'), params, found);
        }

        if (found) {
            for (let param in params) {
                found = found.replaceAll(param, params[param]);
            }
        } else {
            return '<translation not-found>';
        }

        return found;
    }
}

/**
 * Alias method to trans load.
 *
 * @param domains
 * @returns {Promise<void>}
 */
export async function trans_load(domains) {
    await Translation.loadFromApi(domains);
}

/**
 * Alias method to translate.
 * @param {object} args
 * @returns {string}
 */
export function trans(...args) {
    return Translation.find(...args);
}
