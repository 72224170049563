<template>
    <div>
        <div class="recap-block-participants">
            <h4>{{ 'form.recap.participant.title'|trans({'%nb%': getNotGuestParticipants.length}) }}</h4>

            <div class="recap-block-participant" v-for="(participant, index) in participants" :key="participant.idx"
                 :class="{'first-child': index === 0}" v-if="!isGuest(participant)">
                <div class="recap-block-participant-wrapper"
                     :id="'form.recap.participant.id'|trans({'%id%': participant.uidx.replace('.', '') })">
                    <div v-if="getCompleteName(participant) === null" class="recap-block-participant-name">
                        <div class="bold" :inner-html.prop="'form.recap.participant.label.default'|trans({
                        '%n%': index + 1
                    })" @click="scroll(getAnchor(participant))"></div>
                    </div>

                    <div v-else-if="getCompleteName(participant)" class="recap-block-participant-name">
                        <div class="bold" :inner-html.prop="'form.recap.participant.label.withName'|trans({
                        '%n%': index + 1,
                        '%type%': getTypeLabel(participant),
                        '%name%': getCompleteName(participant)
                    })" @click="scroll(getAnchor(participant))"></div>
                    </div>

                    <div v-if="displayCompleteInformation">
                        <div class="recap-block-participant-complete-infos">
                            <div v-if="participant.email"
                                 :inner-html.prop="'form.recap.participant-accompagnant-commons.email'|trans({'%email%': participant.email})"></div>
                            <div v-if="participant.mobileNumber"
                                 :inner-html.prop="'form.recap.participant-accompagnant-commons.mobileNumber'|trans({'%mobileNumber%': participant.mobileNumber})"></div>
                            <div v-if="hasGuest(participant)"
                                 :inner-html.prop="'form.recap.participant.hasGuests'|trans()"></div>
                            <div v-if="hasGuest(participant)" class="recap-block-participant-list-accompagnants">
                                <div v-for="(accompagnant, index) in getGuests(participant)" :key="participant.idx">
                                    <div :inner-html.prop="'form.recap.accompagnantsList.label.withName'|trans({
                        '%n%': index + 1,
                        '%type%': getTypeLabel(accompagnant),
                        '%name%': getCompleteName(accompagnant)
                    })" @click="scroll(getRecapAnchor(accompagnant)); highlightCard(accompagnant)"></div>
                                </div>
                            </div>


                        </div>

                        <form-recap-identity-document
                            :identitydocument="findIdentityDocument(participant.uidx)"></form-recap-identity-document>
                        <form-recap-transport :transport="findTransport(participant.uidx)"></form-recap-transport>
                    </div>
                </div>

            </div>
        </div>

        <div class="recap-block-accompagnants" v-if="getGuestParticipants.length > 0">

            <h4>{{ 'form.recap.accompagnants.title'|trans({'%nb%': getGuestParticipants.length}) }}</h4>

            <div class="recap-block-participant" v-for="(participant, index) in getGuestParticipants"
                 :key="participant.idx">
                <div class="recap-block-participant-wrapper"
                     :id="'form.recap.participant.id'|trans({'%id%': participant.uidx.replace('.', '') })">
                    <div v-if="getCompleteName(participant) === null" class="recap-block-participant-name">
                        <div class="bold" :inner-html.prop="'form.recap.accompagnants.label.default'|trans({
                        '%n%': index + 1
                    })" @click="scroll(getAnchor(participant))"></div>
                    </div>

                    <div v-else-if="getCompleteName(participant)" class="recap-block-participant-name">
                        <div class="bold" :inner-html.prop="'form.recap.accompagnants.label.withName'|trans({
                        '%n%': index + 1,
                        '%type%': getTypeLabel(participant),
                        '%name%': getCompleteName(participant)
                    })" @click="scroll(getAnchor(participant))"></div>
                    </div>

                    <div v-if="displayCompleteInformation">
                        <div class="recap-block-participant-complete-infos">
                            <div v-if="participant.email"
                                 :inner-html.prop="'form.recap.participant-accompagnant-commons.email'|trans({'%email%': participant.email})"></div>
                            <div v-if="participant.mobileNumber"
                                 :inner-html.prop="'form.recap.participant-accompagnant-commons.mobileNumber'|trans({'%mobileNumber%': participant.mobileNumber})"></div>
                            <div v-if="isGuest(participant)" class="recap-block-participant-list-parent">
                                <div
                                    :inner-html.prop="'form.recap.accompagnants.parent'|trans({'%parent%': getCompleteName(getParent(participant))})"
                                    @click="scroll(getRecapAnchor(getParent(participant)));highlightCard(getParent(participant))"></div>
                            </div>

                        </div>

                        <form-recap-identity-document
                            :identitydocument="findIdentityDocument(participant.uidx)"></form-recap-identity-document>
                        <form-recap-transport :transport="findTransport(participant.uidx)"></form-recap-transport>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {scroll} from "../../../../../common/functions";
import {highlightCard} from "../../../../../common/functions";
import FormRecapIdentityDocument from "./IdentityDocument/FormRecapIdentityDocument";
import FormRecapTransport from "./Transport/FormRecapTransport.vue";

export default {
    components: {
        FormRecapIdentityDocument,
        FormRecapTransport
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            participants: state => state.participantCollection.all
        }),
        ...mapGetters({
            getCompleteName: 'participantCollection/getCompleteName',
            getTypeLabel: 'participantCollection/getTypeLabel',
            countCom: 'participantCollection/countCom',
            isGuest: 'participantCollection/isGuest',
            hasGuest: 'participantCollection/hasGuest',
            getParent: 'participantCollection/getParent',
            getGuests: 'participantCollection/getGuests',
            findIdentityDocument: 'identityDocument/findByParticipantUidx',
            findTransport: 'transport/findByParticipantUidx',
            getGuestParticipants: 'participantCollection/getGuestParticipants',
            getNotGuestParticipants: 'participantCollection/getNotGuestParticipants'
        }),

        displayCompleteInformation() {
            return this.$parent.displayCompleteInformation;
        }
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */

        scroll,
        highlightCard,

        /**
         * Get the anchor ID of the participant.
         *
         * @param {object} participant
         * @returns {string}
         */
        getAnchor(participant) {
            return (this.isGuest(participant))
                ? 'guest-' + participant.idx
                : 'participant-' + participant.idx;
        },

        getRecapAnchor(participant) {
            return (this.isGuest(participant))
                ? 'recap-participant-' + participant.uidx.replace('.', '')
                : 'recap-participant-' + participant.uidx.replace('.', '')
        },


    }
};
</script>
