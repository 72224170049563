var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "recap-block-participants" },
      [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm._f("trans")("form.recap.participant.title", {
                "%nb%": _vm.getNotGuestParticipants.length,
              })
            )
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.participants, function (participant, index) {
          return !_vm.isGuest(participant)
            ? _c(
                "div",
                {
                  key: participant.idx,
                  staticClass: "recap-block-participant",
                  class: { "first-child": index === 0 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "recap-block-participant-wrapper",
                      attrs: {
                        id: _vm._f("trans")("form.recap.participant.id", {
                          "%id%": participant.uidx.replace(".", ""),
                        }),
                      },
                    },
                    [
                      _vm.getCompleteName(participant) === null
                        ? _c(
                            "div",
                            { staticClass: "recap-block-participant-name" },
                            [
                              _c("div", {
                                staticClass: "bold",
                                domProps: {
                                  innerHTML: _vm._f("trans")(
                                    "form.recap.participant.label.default",
                                    {
                                      "%n%": index + 1,
                                    }
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.scroll(_vm.getAnchor(participant))
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm.getCompleteName(participant)
                        ? _c(
                            "div",
                            { staticClass: "recap-block-participant-name" },
                            [
                              _c("div", {
                                staticClass: "bold",
                                domProps: {
                                  innerHTML: _vm._f("trans")(
                                    "form.recap.participant.label.withName",
                                    {
                                      "%n%": index + 1,
                                      "%type%": _vm.getTypeLabel(participant),
                                      "%name%":
                                        _vm.getCompleteName(participant),
                                    }
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.scroll(_vm.getAnchor(participant))
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayCompleteInformation
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "recap-block-participant-complete-infos",
                                },
                                [
                                  participant.email
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._f("trans")(
                                            "form.recap.participant-accompagnant-commons.email",
                                            { "%email%": participant.email }
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  participant.mobileNumber
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._f("trans")(
                                            "form.recap.participant-accompagnant-commons.mobileNumber",
                                            {
                                              "%mobileNumber%":
                                                participant.mobileNumber,
                                            }
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasGuest(participant)
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._f("trans")(
                                            "form.recap.participant.hasGuests"
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasGuest(participant)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "recap-block-participant-list-accompagnants",
                                        },
                                        _vm._l(
                                          _vm.getGuests(participant),
                                          function (accompagnant, index) {
                                            return _c(
                                              "div",
                                              { key: participant.idx },
                                              [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._f("trans")(
                                                      "form.recap.accompagnantsList.label.withName",
                                                      {
                                                        "%n%": index + 1,
                                                        "%type%":
                                                          _vm.getTypeLabel(
                                                            accompagnant
                                                          ),
                                                        "%name%":
                                                          _vm.getCompleteName(
                                                            accompagnant
                                                          ),
                                                      }
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.scroll(
                                                        _vm.getRecapAnchor(
                                                          accompagnant
                                                        )
                                                      )
                                                      _vm.highlightCard(
                                                        accompagnant
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("form-recap-identity-document", {
                                attrs: {
                                  identitydocument: _vm.findIdentityDocument(
                                    participant.uidx
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("form-recap-transport", {
                                attrs: {
                                  transport: _vm.findTransport(
                                    participant.uidx
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            : _vm._e()
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.getGuestParticipants.length > 0
      ? _c(
          "div",
          { staticClass: "recap-block-accompagnants" },
          [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm._f("trans")("form.recap.accompagnants.title", {
                    "%nb%": _vm.getGuestParticipants.length,
                  })
                )
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.getGuestParticipants, function (participant, index) {
              return _c(
                "div",
                {
                  key: participant.idx,
                  staticClass: "recap-block-participant",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "recap-block-participant-wrapper",
                      attrs: {
                        id: _vm._f("trans")("form.recap.participant.id", {
                          "%id%": participant.uidx.replace(".", ""),
                        }),
                      },
                    },
                    [
                      _vm.getCompleteName(participant) === null
                        ? _c(
                            "div",
                            { staticClass: "recap-block-participant-name" },
                            [
                              _c("div", {
                                staticClass: "bold",
                                domProps: {
                                  innerHTML: _vm._f("trans")(
                                    "form.recap.accompagnants.label.default",
                                    {
                                      "%n%": index + 1,
                                    }
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.scroll(_vm.getAnchor(participant))
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm.getCompleteName(participant)
                        ? _c(
                            "div",
                            { staticClass: "recap-block-participant-name" },
                            [
                              _c("div", {
                                staticClass: "bold",
                                domProps: {
                                  innerHTML: _vm._f("trans")(
                                    "form.recap.accompagnants.label.withName",
                                    {
                                      "%n%": index + 1,
                                      "%type%": _vm.getTypeLabel(participant),
                                      "%name%":
                                        _vm.getCompleteName(participant),
                                    }
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.scroll(_vm.getAnchor(participant))
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayCompleteInformation
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "recap-block-participant-complete-infos",
                                },
                                [
                                  participant.email
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._f("trans")(
                                            "form.recap.participant-accompagnant-commons.email",
                                            { "%email%": participant.email }
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  participant.mobileNumber
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._f("trans")(
                                            "form.recap.participant-accompagnant-commons.mobileNumber",
                                            {
                                              "%mobileNumber%":
                                                participant.mobileNumber,
                                            }
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isGuest(participant)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "recap-block-participant-list-parent",
                                        },
                                        [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._f("trans")(
                                                "form.recap.accompagnants.parent",
                                                {
                                                  "%parent%":
                                                    _vm.getCompleteName(
                                                      _vm.getParent(participant)
                                                    ),
                                                }
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.scroll(
                                                  _vm.getRecapAnchor(
                                                    _vm.getParent(participant)
                                                  )
                                                )
                                                _vm.highlightCard(
                                                  _vm.getParent(participant)
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("form-recap-identity-document", {
                                attrs: {
                                  identitydocument: _vm.findIdentityDocument(
                                    participant.uidx
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("form-recap-transport", {
                                attrs: {
                                  transport: _vm.findTransport(
                                    participant.uidx
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }