import Vue from "vue/dist/vue";
import store from "./store";
import RegistrationForm from "./components/Registration/RegistrationForm";
import VueFilters from "../../common/Vue/VueFilters";
import {mapState} from "vuex";
import $ from "jquery";
import {trans_load} from "../../common/Translation/Translation";
import {ske_load} from "../../common/Ske/Ske";

export class PageRegistration {
    static initialize() {
        (async () => {
            await ske_load('api');
            await trans_load(['form', 'entity']);
        })().then(() => {
            VueFilters
                .addReplace(Vue)
                .addTranslation(Vue)
                .addMoment(Vue)
                .addMedia(Vue)
            ;

            this.vue = new Vue({
                el: "#registration-form-container",
                store,
                components: {
                    RegistrationForm
                },
                computed: {
                    ...mapState({
                        isLoaded: state => state.data._isLoaded || false
                    }),
                    isEditMode() {
                        return this.$refs.registration.isEditMode;
                    }
                },
                async created() {
                    await this.$store.dispatch('data/loadApi');
                    await this.$store.dispatch('roomTypes/load');
                    await this.$store.dispatch('hotels/load');
                    await this.$store.dispatch('availabilities/load');
                    await this.$store.dispatch('placements/load');
                    await this.$store.dispatch('participationPack/loadApi');
                    await this.$store.dispatch('data/loadRegistration');
                    await this.$store.dispatch('agency/load');

                    await this.$store.dispatch('participantCollection/load');
                    await this.$store.dispatch('roomCollection/load');

                    window.addEventListener('FORM_VALIDATION_ERROR', async () => {
                        await this.$store.dispatch('data/loadApi');
                        await this.$store.dispatch('availabilities/load');
                        await this.$store.dispatch('placements/reload');
                    });

                    $('#registration-form-container').parent().find('.loader-dots-container').fadeOut(400, () => {
                        $(this).remove();
                        $('#registration-form-container').fadeIn();
                    });
                }
            });
        });
    }
}
