import Vue from "vue";
import {Ske} from "../../../../../common/Ske/Ske";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * Use the VuexState#create method to define your state:
     *     VuexState.create({ ... })
     *
     * The "create" static method needs one object argument, containing all property names as keys,
     * and property object as values.
     *
     * Property objects should have a "type" property. They could also have two facultative properties:
     * - "formatter", which must have the name of one of the methods of the FieldFormatter class.
     * - "default", which is the default value of the property.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        _roomState: () => ({
            id: null,
            idx: null,
            occupantsUidx: [],

            roomTypeId: null,
            hotelId: null
        }),

        all: [],
        currentRoomIdx: 0,
        wantRooming: false
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        findRoomByIdx: (state) => (idx) => {
            return state.all.find(r => (r.idx === +idx));
        },
        isParticipantInRoom: () => (room, participantUidx) => {
            return (room.occupantsUidx.findIndex(uidx => (uidx === participantUidx)) > -1);
        },
        findRoomByParticipant: (state) => (participant) => {
            for (let room of state.all) {
                const filtered = room.occupantsUidx.filter(uidx => (uidx === participant.uidx));

                if (filtered.length > 0) {
                    return room;
                }
            }

            return null;
        },
        getByRoomType: (state) => (roomTypeId) => {
            if (typeof roomTypeId === 'object') {
                roomTypeId = roomTypeId.id;
            }

            return state.all.filter(r => (r.roomTypeId === +roomTypeId));
        },
        getByHotelAndRoomType: (state) => (hotelId, roomTypeId) => {
            if (typeof hotelId === 'object') {
                hotelId = hotelId.id;
            }

            if (typeof roomTypeId === 'object') {
                roomTypeId = roomTypeId.id;
            }

            return state.all.filter(r => (r.roomTypeId === +roomTypeId && r.hotelId === +hotelId));
        },
        getOccupants: (state, getters, rootState, rootGetters) => (room) => {
            return room.occupantsUidx.map(uidx => rootGetters["participantCollection/getParticipantByUidx"](uidx));
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        load({rootState, rootGetters, dispatch}) {
            const data = rootState.data.data;

            let wantRooming = true;

            if (data) {
                const {rooms} = data;

                if (rooms.length > 0) {
                    wantRooming = true;
                }

                for (const room of rooms) {
                    room.occupantsUidx = room.occupants.map(o => rootGetters["participantCollection/getParticipantById"](o.id).uidx);
                    room.roomTypeId = room.roomType.id;
                    room.hotelId = room.hotel.id;

                    delete room.occupants;
                    delete room.roomType;
                    delete room.hotel;

                    dispatch('add', {data: room});
                }
            }

            dispatch('updateProp', {property: 'wantRooming', value: wantRooming});
        },
        add({commit}, {data = {}}) {
            commit('add', {data});
        },
        update({commit}, {idx, data}) {
            commit('update', {idx, data});
        },
        remove({commit}, room) {
            // This action is captured by removeFromCollectionPlugin.
            // The mutations are called from there.
        },
        updateProp({commit}, {property, value}) {
            commit('updateProperty', {property, value});
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        add(state, {data = {}}) {
            const room = {
                ...state._roomState(),
                ...data,
                idx: state.currentRoomIdx
            };

            state.all.push(room);

            Vue.set(state, 'currentRoomIdx', state.currentRoomIdx + 1);
        },
        update(state, {data}) {
            const index = state.all.findIndex(r => (r.idx === +data.idx));

            for (const prop in data) {
                Vue.set(state.all[index], prop, data[prop]);
            }
        },
        remove(state, {idx}) {
            const index = state.all.findIndex(r => (r.idx === +idx));

            state.all.splice(index, 1);
        },
        updateProperty(state, {property, value}) {
            Vue.set(state, property, value);
        }
    }
}
