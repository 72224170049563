import registrationApi from "../../api/registration";
import {FormHandler} from "../../../../common/Form/FormHandler";
import Vue from "vue";

/**
 * The form handler.
 *
 * @var {FormHandler}
 */
const formHandler = new FormHandler('#registration-form');

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        isSending: false
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {},

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        /**
         * Submit the form.
         *
         * @param {function} commit
         * @param {function} dispatch
         * @returns {Promise<void>}
         */
        async submitForm({commit, dispatch}) {
            formHandler.preventUnload = true;

            dispatch('update', {
                data: {
                    isSending: true
                }
            });

            const
                registrationForm = document.getElementById("registration-form"),
                data = new FormData(registrationForm)
            ;

            registrationApi.post(data)
                .then(response => {
                    const {data = null} = response;

                    formHandler.onFormSuccess(data);
                })
                .catch(error => {
                    const {response = null} = error;

                    formHandler.onFormError(response);
                })
                .finally(() => {
                    dispatch('update', {
                        data: {
                            isSending: false
                        }
                    });
                })
            ;
        },
        update({commit}, {data}) {
            commit('update', {data});
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        update(state, {data}) {
            for (const prop in data) {
                Vue.set(state, prop, data[prop]);
            }
        }
    }
}
