import Vue from "vue/dist/vue"
import Vuex from 'vuex/dist/vuex'
import {createLogger} from 'vuex'
import data from "./modules/data";
import registration from "./modules/registration";
import participantCollection from "./modules/participantCollection";
import identityDocument from "./modules/participantCollection/identityDocument";
import transport from "./modules/participantCollection/transport";
import participationPack from "./modules/participantCollection/participationPack";
import roomCollection from "./modules/rooming/roomCollection";
import agency from "./modules/agency";
import billing_order from "./modules/billing";
import billing_contact from "./modules/billing/billingContact";
import submitRegistrationButton from "./modules/submitRegistrationButton";
import roomTypes from "./modules/rooming/roomTypes";
import hotels from "./modules/rooming/hotels";
import availabilities from "./modules/availabilities";
import placements from "./modules/placement";
import removeFromCollectionPlugin from "./plugins/removeFromCollectionPlugin";

Vue.use(Vuex);

const isDebugModeEnabled = (process.env.NODE_ENV !== 'production');

export default new Vuex.Store({
    modules: {
        data,
        registration,
        agency,
        billing_order,
        billing_contact,
        participantCollection,
        identityDocument,
        transport,
        participationPack,
        roomCollection,
        submitRegistrationButton,
        roomTypes,
        hotels,
        availabilities,
        placements
    },
    mode: process.env.NODE_ENV,
    strict: isDebugModeEnabled,
    plugins: (isDebugModeEnabled)
        ? [createLogger(), removeFromCollectionPlugin()]
        : [removeFromCollectionPlugin()]
})
