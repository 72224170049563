import Vue from "vue";
import {trans} from "../../../../../../common/Translation/Translation";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * @property {object}
     */
    state: () => ({
        _identityDocumentState: () => ({
            type: null,
            number: null,
            lastNames: null,
            firstNames: null,
            nationality: null,
            dateOfIssue: null,
            dateOfExpiry: null,
            dateOfBirth: null,
            filenameFront: null,
            filenameBack: null,

            participantUidx: null
        }),

        all: []
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        findByParticipantUidx: (state) => (participantUidx) => {
            return state.all.find(doc => (doc.participantUidx === participantUidx));
        },

        isType: (state) => (identityDocument, type) => {
            return (identityDocument.type === type);
        },
        isTypeIdentityCard: (state, getters) => (identityDocument) => {
            return getters.isType(identityDocument, 'identity-card');
        },
        isTypePassport: (state, getters) => (identityDocument) => {
            return getters.isType(identityDocument, 'passport');
        },
        isTypeBeingRenewed: (state, getters) => (identityDocument) => {
            return getters.isType(identityDocument, 'being-renewed');
        },
        isTypeSendLater: (state, getters) => (identityDocument) => {
            return getters.isType(identityDocument, 'send-later');
        },
        getTypeLabel: () => (identityDocument) => {
            return trans('entity.participant.identity-document.type.' + identityDocument.type + '.label');
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        /**
         * Add a new identity document.
         *
         * @param {function} commit
         * @param {object} data
         */
        add({commit}, {data = {}}) {
            commit('add', {data});
        },

        /**
         * Update an existing identity document.
         *
         * @param {function} commit
         * @param {object} data
         */
        update({commit}, {data}) {
            commit('update', {data})
        },

        /**
         * Remove an existing identity document.
         *
         * @param {function} commit
         * @param {number} participantUidx
         */
        remove({commit}, {participantUidx}) {
            commit('remove', {participantUidx});
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        /*
         * Here, you can import methods from the helper.
         *
         * ...helperMutations(['myMutation']),
         * or
         * ...helperMutations({
         *     otherMutationName: myMutation
         * }),
         */

        /**
         * Add a new identity document.
         *
         * @param {object} state
         * @param {object} data
         */
        add(state, {data = {}}) {
            const identityDocument = {
                ...state._identityDocumentState(),
                ...data
            };

            state.all.push(identityDocument);
        },

        /**
         * Update an existing identity document.
         *
         * @param {object} state
         * @param {object} data
         */
        update(state, {data}) {
            const index = state.all.findIndex(doc => (doc.participantUidx === data.participantUidx));

            for (const prop in data) {
                Vue.set(state.all[index], prop, data[prop]);
            }
        },

        /**
         * Delete an existing identity document.
         *
         * @param {object} state
         * @param {number} participantUidx
         */
        remove(state, {participantUidx}) {
            const index = state.all.findIndex(doc => (doc.participantUidx === participantUidx));

            state.all.splice(index, 1);
        }
    },
}
