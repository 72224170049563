import Vue from "vue/dist/vue"
import {mapActions, mapState} from "vuex";
import PlacementForm from "./PlacementForm";
import PlacementSearch from "./PlacementSearch";

export default Vue.component('PlacementCollection', {
    components: {
        PlacementForm,
        PlacementSearch
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        ...mapState({
            registration: state => state.registration,
            participants: state => state.participantCollection.all
        })
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        ...mapActions({
            refresh: 'placements/reload'
        }),

        isDisplayed() {
            return (this.registration.isParticipating && this.participants.length > 0);
        }
    },

    /**
     * Executed when each time the component is created.
     *
     * @method
     * @returns {void}
     */
    created() {

    }
})

