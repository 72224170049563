import Vue from "vue";
import roomingApi from "../../api/rooming";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * Use the VuexState#create method to define your state:
     *     VuexState.create({ ... })
     *
     * The "create" static method needs one object argument, containing all property names as keys,
     * and property object as values.
     *
     * Property objects should have a "type" property. They could also have two facultative properties:
     * - "formatter", which must have the name of one of the methods of the FieldFormatter class.
     * - "default", which is the default value of the property.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        _isLoaded: false,
        data: {},
        hotelRoomTypePivot: []
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        /*
         * Here, you can import methods from the helper.
         *
         * ...helperGetters(['myGetter']),
         * or
         * ...helperGetters({
         *     otherGetterName: myGetter
         * }),
         */
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        /**
         * Load initialize states.
         *
         * @param {function} commit
         * @returns {Promise}
         */
        loadRegistration({commit}) {
            return new Promise(resolve => {
                commit('update', {
                    data: {
                        data: window.registrationData || {},
                        _isLoaded: true
                    }
                });

                window.registrationData = undefined;

                return resolve();
            });
        },

        async loadApi({commit}) {
            const response = await roomingApi.hotelRoomTypePivot.all();

            commit('reset');

            commit('update', {
                data: {
                    hotelRoomTypePivot: response.data
                }
            });

            return response;
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        update(state, {data}) {
            for (const prop in data) {
                Vue.set(state, prop, data[prop]);
            }
        },
        reset(state) {
            state.data = {};
            state.hotelRoomTypePivot = [];
        }

    }
}
