<template>
  <div v-if="identityDocument.type" class="recap-block-participant-identity-doc">
    <h5>{{ 'form.recap.participant-accompagnant-commons.identity-document.title'|trans }}</h5>

    <div class="recap-block-participant-identity-doc-type">{{ getTypeLabel(identityDocument) }}</div>

    <div v-if="areAllInputsDisplayed">
      <div v-if="identityDocument.number">
        {{ 'form.recap.participant-accompagnant-commons.identity-document.number'|trans({'%number%': identityDocument.number}) }}
      </div>
      <div v-if="identityDocument.lastNames">
        {{ 'form.recap.participant-accompagnant-commons.identity-document.lastNames'|trans({'%names%': identityDocument.lastNames}) }}
      </div>
      <div v-if="identityDocument.firstNames">
        {{ 'form.recap.participant-accompagnant-commons.identity-document.firstNames'|trans({'%names%': identityDocument.firstNames}) }}
      </div>
      <div v-if="identityDocument.nationality">{{
          'form.recap.participant-accompagnant-commons.identity-document.nationality'|trans({'%nationality%': identityDocument.nationality})
        }}
      </div>
      <div v-if="identityDocument.dateOfIssue">
        {{ 'form.recap.participant-accompagnant-commons.identity-document.dateOfIssue'|trans({'%date%': identityDocument.dateOfIssue}) }}
      </div>
      <div v-if="identityDocument.dateOfExpiry">
        {{ 'form.recap.participant-accompagnant-commons.identity-document.dateOfExpiry'|trans({'%date%': identityDocument.dateOfExpiry}) }}
      </div>
      <div v-if="identityDocument.dateOfBirth">
        {{ 'form.recap.participant-accompagnant-commons.identity-document.dateOfBirth'|trans({'%date%': identityDocument.dateOfBirth}) }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['identitydocument'],

  /**
   * All computed properties of the component.
   *
   * @property {object}
   */
  computed: {
    /*
     * Import properties of states:
     *
     * ...mapState(['namespace/property'])
     * ...mapState('namespace', ['property', 'property'])
     * ...mapState({
     *     propertyAlias: state => state.namespace.property,
     *     propertyAlias: state => state.namespace.property
     * })
     *
     * Then use them:
     * - in Vue templates: {{ property }} or {{ propertyAlias }}
     * - in here: this.property or this.propertyAlias
     *
     * Import getters of modules:
     *
     * ...mapGetters(['namespace/getter'])
     * ...mapGetters('namespace', ['getter', 'getter'])
     * ...mapGetters({
     *     getterAlias: 'namespace/getter',
     *     getterAlias: 'namespace/getter'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
     * - in here: this.getter() or this.getterAlias()
     */
    ...mapGetters({
      isTypeIdentityCard: 'identityDocument/isTypeIdentityCard',
      isTypePassport: 'identityDocument/isTypePassport',
      getTypeLabel: 'identityDocument/getTypeLabel'
    }),

    identityDocument() {
      return this.identitydocument;
    },
    areAllInputsDisplayed() {
      return false;//this.isTypeIdentityCard(this.identityDocument) || this.isTypePassport(this.identityDocument);
    }
  },

  /**
   * All methods of the component.
   *
   * @property {object}
   */
  methods: {
    /*
     * Import actions of modules:
     *
     * ...mapActions(['namespace/action'])
     * ...mapActions('namespace', ['action', 'action'])
     * ...mapActions({
     *     actionAlias: 'namespace/action',
     *     actionAlias: 'namespace/action'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ action() }} or {{ actionAlias() }}
     * - in here: this.action() or this.actionAlias()
     *
     * Import mutations of modules:
     *
     * ...mapMutations(['namespace/mutation'])
     * ...mapMutations('namespace', ['mutation', 'mutation'])
     * ...mapMutations({
     *     mutationAlias: 'namespace/mutation',
     *     mutationAlias: 'namespace/mutation'
     * })
     *
     * Then use them:
     * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
     * - in here: this.mutation() or this.mutationAlias()
     */
  }
};
</script>
