import {trans} from "../../../../common/Translation/Translation";

export default function removeFromCollectionPlugin() {
    return (store) => {
        store.subscribeAction({
                before: (action) => {
                    // Only for removing a participant or one of his guest.
                    if (action.type === 'participantCollection/remove') {
                        const
                            participant = action.payload,
                            hasGuest = store.getters['participantCollection/hasGuest'](participant)
                        ;

                        // If the rooming module exists, check if the concerned participant is into a room.
                        // If that's the case, stop the removal process.
                        if (store.hasModule('roomCollection')) {
                            const isInRoom = (store.getters['roomCollection/findRoomByParticipant'](participant) !== null);

                            if (isInRoom) {
                                let alertMessage = trans('form.rooming.alert.removeParticipant');

                                if (hasGuest) {
                                    alertMessage = trans('form.rooming.alert.removeParticipantAndGuests');
                                }

                                alert(alertMessage);

                                return;
                            }
                        }

                        // Removal process.
                        let confirmMessage = trans('form.participant.confirm.removeParticipant');

                        if (hasGuest) {
                            confirmMessage = trans('form.participant.confirm.removeParticipantAndGuests');
                        }

                        if (confirm(confirmMessage)) {
                            store.commit('participantCollection/remove', {uidx: participant.uidx});

                            // Update his seat if he has one.
                            if (participant.seatId !== null) {
                                const seat = store.getters['placements/findSeatById'](participant.seatId);

                                store.dispatch('placements/updateSeat', {
                                    ...seat,
                                    participant: null
                                });
                            }
                        }
                    }

                    // Only for removing a room.
                    if (action.type === 'roomCollection/remove') {
                        const
                            room = action.payload,
                            confirmMessage = trans('form.rooming.confirm.removeRoom')
                        ;

                        if (confirm(confirmMessage)) {
                            store.commit('roomCollection/remove', {idx: room.idx});
                        }
                    }
                },
                after: (action) => {}
            }
        )
    }
}
