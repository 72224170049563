import Vue from "vue/dist/vue";
import {mapActions, mapState} from "vuex";
import $ from "jquery";
import {updateState} from "../../../../common/Vue/VueHelper";

export default Vue.component('CentralizerForm', {
    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            registration: state => state.registration
        })
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions({
            update: 'registration/update'
        }),

        /**
         * Get the value from the event, then pass it to the update action.
         *
         * @param {string} prop
         * @param {Event} event
         */
        updateStateProperty(prop, event) {
            updateState(this.registration, prop, event, this.update);
        }
    }
});

