import {mapActions, mapGetters} from 'vuex';
import Vue from "vue/dist/vue";
import {updateState} from "../../../../../../../common/Vue/VueHelper";

export default Vue.component('GuestForm', {
    /**
     * Local properties of the components.
     * They are obtained from the Vue template.
     *
     * If you define a "user" property, then in your template, you should have:
     * <my-component :user="..."> [...] </my-component>
     *
     * @property {array}
     */
    props: ['parent', 'guest', 'index'],

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapGetters({
            getCompleteName: 'participantCollection/getCompleteName',
            getTypeLabel: 'participantCollection/getTypeLabel',
            countCom: 'participantCollection/countCom',
        }),

        /**
         * Get the UIDX of the guest.
         *
         * @returns {string}
         */
        uidx() {
            return this.guest.uidx;
        },

        /**
         * Get the type of the guest.
         *
         * @returns {string}
         */
        type() {
            return this.guest.type;
        },

        /**
         * Get the civility of the guest.
         *
         * @returns {?string}
         */
        civility() {
            return this.guest.civility;
        },

        /**
         * Get the last name of the guest.
         *
         * @returns {?string}
         */
        lastName() {
            return this.guest.lastName;
        },

        /**
         * Get the first name of the guest.
         *
         * @returns {?string}
         */
        firstName() {
            return this.guest.firstName;
        },

        /**
         * Get the email address of the guest.
         *
         * @returns {?string}
         */
        email() {
            return this.guest.email;
        },

        /**
         * Get the mobile number of the guest.
         *
         * @returns {?string}
         */
        mobileNumber() {
            return this.guest.mobileNumber;
        },

        /**
         * Get the remarks of the guest.
         *
         * @returns {?string}
         */
        remarks() {
            return this.guest.remarks;
        },

        participationPackIds() {
            return this.guest.participationPackIds;
        },

        hasInsurance() {
            return this.guest.hasInsurance;
        },

        comments() {
            return this.guest.comments;
        },
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions('participantCollection', ['update', 'remove']),

        /**
         * Get the value from the event, then pass it to the update action.
         *
         * @param {string} prop
         * @param {Event} event
         */
        updateState(prop, event) {
            updateState(this.guest, prop, event, this.update);
        },
    }
})

