var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "placement-block-search" }, [
    _c("div", {}, [
      _c("div", { staticClass: "registration-block-sub-title" }, [
        _c("p", [
          _vm._v(_vm._s(_vm._f("trans")("form.placement.search.label"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "registration-block-sub-title-headline" }, [
          _c("p", [
            _vm._v(_vm._s(_vm._f("trans")("form.placement.search.help"))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "placement-block-search-input" }, [
        _c("input", {
          attrs: { type: "text" },
          domProps: { value: _vm.query },
          on: {
            input: function ($event) {
              return _vm.updateQuery($event)
            },
          },
        }),
        _vm._v(" "),
        _vm.hasSearched()
          ? _c(
              "button",
              {
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.reset()
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      fill: "none",
                      height: "33",
                      viewBox: "0 0 24 24",
                      width: "33",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "m16 8-8 8m.00001-8 7.99999 8",
                        stroke: "#000",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "1.5",
                      },
                    }),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.hasSearched()
        ? _c("div", { staticClass: "placement-block-search-results" }, [
            _vm.isLoading
              ? _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "form.placement.search.results.title.in-progress"
                      )
                    )
                  ),
                ])
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "placement-block-search-results-title" },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")(
                              "form.placement.search.results.title.done"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "placement-block-search-results-list" },
                    [
                      _c("table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("trans")(
                                    "form.placement.search.results.table.head.participant"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("trans")(
                                    "form.placement.search.results.table.head.seat"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("trans")(
                                    "form.placement.search.results.table.head.left-free-seats"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.results, function (item) {
                              return _vm.hasResults()
                                ? _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(item.firstName) +
                                          " " +
                                          _vm._s(item.lastName)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    item.seat === null
                                      ? _c("td", { attrs: { colspan: "99" } }, [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("trans")(
                                                  "form.placement.search.results.table.body.empty-seat"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.seat
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("trans")(
                                                  "entity.placement.zone." +
                                                    item.seat.zone.uid +
                                                    ".label"
                                                )
                                              ) +
                                              " /\n                " +
                                              _vm._s(
                                                _vm._f("trans")(
                                                  "form.placement.search.results.table.body.left-free-seats.label",
                                                  { "%seat%": item.seat.name }
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.seat
                                      ? _c("td", [
                                          _vm.countFreeSeatsOfZone(
                                            _vm.findZone(item.seat.zone.id)
                                          ) > 0
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                  " +
                                                    _vm._s(
                                                      _vm._f("trans")(
                                                        "form.placement.search.results.table.body.left-free-seats.availabilities.many",
                                                        {
                                                          "%n%":
                                                            _vm.countFreeSeatsOfZone(
                                                              _vm.findZone(
                                                                item.seat.zone
                                                                  .id
                                                              )
                                                            ),
                                                        }
                                                      )
                                                    ) +
                                                    "\n                              "
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                                  " +
                                                    _vm._s(
                                                      _vm._f("trans")(
                                                        "form.placement.search.results.table.body.left-free-seats.availabilities"
                                                      )
                                                    ) +
                                                    "\n                              "
                                                ),
                                              ]),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e()
                            }),
                            _vm._v(" "),
                            !_vm.hasResults()
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "empty",
                                      attrs: { colspan: "99" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("trans")(
                                            "form.placement.search.results.table.body.empty"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]
                  ),
                ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }