import ObjectResolver from "../ObjectResolver/ObjectResolver";
import axios from "axios";

export class Ske {
    /**
     * Whether the config have been loaded or not.
     *
     * @type {boolean}
     * @private
     */
    static _loaded = false;

    /**
     * All SKE config given by the server.
     *
     * @type {object}
     */
    static data = {};

    /**
     * Tell whether the config have been loaded or not.
     *
     * @returns {boolean}
     */
    static isLoaded() {
        return this._loaded;
    }

    /**
     * Throw an error is the config has not been loaded.
     *
     * @returns {void}
     */
    static checkIfHasBeenLoaded() {
        if (!this.isLoaded()) {
            throw new Error('SKE config has not been loaded. Please do it by using load() or loadFromApi().');
        }
    }

    /**
     * Initialize the data from API call.
     *
     * @returns {Promise<void>}
     */
    static async loadFromApi() {
        if (!this.isLoaded()) {
            const data = (await axios.get('/api/ske/')).data.data;

            this.set(data || {});

            this._loaded = true;

            if (Object.keys(this.data).length === 0) {
                console.warn('You have no SKE config.');
            }
        }
    }

    /**
     * Initialize the data from Window.
     *
     * @returns {void}
     */
    static load() {
        if (!this.isLoaded()) {
            this.set(window._ske || {});

            if (window._ske) {
                window._ske = null;
            }

            this._loaded = true;

            if (Object.keys(this.data).length === 0) {
                console.warn('You have no SKE config.');
            }
        }
    }

    /**
     * Get the app config.
     *
     * @returns {Object}
     */
    static get() {
        this.checkIfHasBeenLoaded();

        return this.data;
    }

    /**
     * Set the app config.
     *
     * @param {object} data
     * @returns {this}
     */
    static set(data) {
        this.data = data;

        return this;
    }

    /**
     * Find an app config from a path.
     *
     * @param {string|null} path
     * @returns {any}
     */
    static find(path = null) {
        this.checkIfHasBeenLoaded();

        return ObjectResolver.resolveWithNull(this.get(), path);
    }
}

/**
 * Alias method to SKE config load.
 *
 * @param from
 * @returns {Promise<void>}
 */
export async function ske_load(from) {
    if (from === 'api') {
        await Ske.loadFromApi();
    }

    Ske.load();
}

/**
 * Alias method to SKE config.
 *
 * @returns {any}
 */
export function ske() {
    return Ske.get();
}
